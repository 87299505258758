var Profile = /** @class */ (function () {
    function Profile() {
        this.name = null;
        this.email = null;
        this.phoneNumber = null;
        this.phone = null;
        this.country = null;
        this.age = null;
        this.amount = null;
        this.redirectUrl = null;
        this.purpose = null;
        this.functionInstanceName = "AMTRUST_INSTAMOJO_CRED";
        this.emailToBeSend = false;
    }
    return Profile;
}());
export { Profile };
