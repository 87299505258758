var Customer = /** @class */ (function () {
    function Customer() {
        this.imei_sno_1 = null;
        this.imei_sno_2 = null;
        this.entityType = null;
        this.product_code = null;
        this.customer_pincode = null;
        this.modifiedTime = 0;
        this.store_state = null;
        this.store_city = null;
        this.partnerCode = null;
        this.isPaymentDone = false;
        this.product_name = null;
        this.customer_email = null;
        this.categoryName = null;
        this.store_district = null;
        this.businessId = null;
        this.premium_paid = null;
        this.brandwarranty = null;
        this.invoice_date = null;
        this.store_location = null;
        this.customer_address = null;
        this.customer_mobile = null;
        this.brandname = null;
        this.payment_mode = null;
        this.invoice_number = null;
        this.store_region = null;
        this.userName = null;
        this.invoice_value = null;
        this.companyContext = null;
        this.createdDt = 0;
        this.customer_fname = null;
        this.device_cost = null;
        this.store_code = null;
        this.udf1 = null;
        this.udf3 = null;
        this.udf2 = null;
        this.udf5 = null;
        this.udf4 = null;
        this.model = null;
        this._id = null;
        this.customer_lname = null;
        this.entityId = null;
        this.isPolicyCancelled = false;
        this.paymentStatus = null;
        this.paymentTransactionRef = null;
    }
    return Customer;
}());
export { Customer };
