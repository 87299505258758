
export class Customer {

    imei_sno_1: string;
    imei_sno_2: string;
    entityType: string;
    product_code: string;
    customer_pincode: string;
    modifiedTime: number;
    store_state: string;
    store_name: string;
    store_city: string;
    partnerCode: string;
    isPaymentDone: boolean;
    product_name: string;
    customer_email: string;
    categoryName: string;
    store_district: string;
    businessId: string;
    premium_paid: string;
    brandwarranty: string;
    invoice_date: string;
    store_location: string;
    customer_address: string;
    customer_mobile: string;
    brandname: string;
    payment_mode: string;
    invoice_number: string;
    store_region: string;
    userName: string;
    invoice_value: string;
    companyContext: string;
    createdDt: number;
    customer_fname: string;
    device_cost: string;
    store_code: string;
    udf1: string;
    udf3: string;
    udf2: string;
    udf5: string;
    udf4: string;
    model: string;
    _id: string;
    customer_lname: string;
    entityId: string;
    isPolicyCancelled: boolean;
    paymentStatus: string;
    paymentTransactionRef: string
     
    constructor() {
        this.imei_sno_1 = null;
        this.imei_sno_2 = null;
        this.entityType = null;
        this.product_code = null;
        this.customer_pincode = null;
        this.modifiedTime = 0;
        this.store_state = null;
        this.store_city = null;
        this.partnerCode = null;
        this.isPaymentDone = false;
        this.product_name= null;
        this.customer_email= null;
        this.categoryName= null;
        this.store_district= null;
        this.businessId= null;
        this.premium_paid= null;
        this.brandwarranty= null;
        this.invoice_date= null;
        this.store_location= null;
        this.customer_address= null;
        this.customer_mobile= null;
        this.brandname= null;
        this.payment_mode= null;
        this.invoice_number= null;
        this.store_region= null;
        this.userName= null;
        this.invoice_value= null;
        this.companyContext= null;
        this.createdDt = 0;
        this.customer_fname= null;
        this.device_cost= null;
        this.store_code= null;
        this.udf1= null;
        this.udf3= null;
        this.udf2= null;
        this.udf5= null;
        this.udf4= null;
        this.model= null;
        this._id= null;
        this.customer_lname= null;
        this.entityId = null;
        this.isPolicyCancelled = false;
        this.paymentStatus = null;
        this.paymentTransactionRef = null;
    }
}