import { CustomerInfo } from './customerInfo.model'
import { Policy } from './policy.model'
import { Claim } from './claim.model'
import { Activity } from './activity.model'
import { Checkers } from './checkers.model'

export class SearchResponse {
    customerInfo: CustomerInfo;
    policies: Policy[];
    claims: Claim[];
    activity: Activity;
    notes: string;

    constructor() {
        this.customerInfo = new CustomerInfo();
        this.policies = [];
        this.claims = [];
        this.activity = new Activity();
        this.notes = null;
    }
}