var RegistrationUser = /** @class */ (function () {
    function RegistrationUser() {
    }
    return RegistrationUser;
}());
export { RegistrationUser };
var NewPasswordUser = /** @class */ (function () {
    function NewPasswordUser() {
    }
    return NewPasswordUser;
}());
export { NewPasswordUser };
