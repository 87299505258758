import { Claim } from "./claim.model";
import { Injectable } from '@angular/core';
import {Customer} from "./customer.model"

@Injectable()
export class VerifyOtp {
    flag: Boolean;
    phoneNumber: string;
    otp: string;
    claim: Claim;
    entityId: string;
    customer: Customer;

    
    constructor() {
        this.flag = false;
        this.phoneNumber = null;
        this.otp = null;
        this.claim = new Claim();
        this.entityId = null;
        this.customer = new Customer();
    }
}