import { Comments } from "./comments.model";

export class Claim {
    claimStatus: string = null;
    status: string = null;
    aging: string = null;
    tatStartDate: string = null;
    tatEndDate: string = null;
    pickupScheduleDate: string = null;
    delieveredToArcDate: string = null;
    pickupRescheduleDate: string = null;
    rtoDate: string = null;
    handsetDispathDate: string = null;
    actualDeliveryToCustomerDate: string = null;
    tbd: string = null;
    subject: string = null;
    homeContract: string = null;
    emailId: string = null;
    completeDescription: string = null;
    incidentDate: string = null;
    incidentHappenAddress: string = null;
    damageType: string = null;
    claimType: string = null;
    claimCause: string = null;
    pickupArea: string = null;
    pickupUnit: string = null;
    pickupCity: string = null;
    pickupState: string = null;
    pickupPinCode: string = null;
    country: string = null;
    dispatchArea: string = null;
    dispatchUnit: string = null;
    dispatchCity: string = null;
    dispatchState: string = null;
    dispatchPinCode: string = null;
    dispatchCountry: string = null;
    applicableFee: string = null;
    paymentStatus: string = null;
    feePaidByCustomer: string = null;
    paymentDate: string = null;
    serviceTransactionRef: string = null;
    pickupAwbNumber: string = null;
    pickupLspName: string = null;
    pickupLspResponse: string = null;
    pickupLspRejection: string = null;
    pickupReschedule: string = null;
    pickupRescheduleCount: string = null;
    dispatchAwbNumber: string = null;
    dispatchLspName: string = null;
    dispatchLspResponse: string = null;
    dispatchLspRejection: string = null;
    dispatchReschedule: string = null;
    dispatchRescheduleCount: string = null;
    fomCoreStatus: string = null;
    arcName: string = null;
    arcAddress: string = null;
    arcCity: string = null;
    arcState: string = null;
    arcPinCode: string = null;
    arcContract: string = null;
    anyLiquidDamage: string = null;
    isDevicePoweringOn: string = null;
    isScreenCracked: string = null;
    isCosmeticDamage: string = null;
    colorOfDevice: string = null;
    memorycapacity: string = null;
    isPhoneLocked: string = null;
    lockCode: string = null;
    isRepairCompletd: string = null;
    repairStatus: string = null;
    repairInvoiceNumber: string = null;
    expectedRepairCompletedDate: string = null;
    invoiceStatus: string = null;
    actualReplacementAmount: number = null;
    revisedExpectedRepairCompletedDate: string = null;
    berStatus: string = null;
    phoneCollected: string = null;
    berApplicableFee: number = null;
    berFeePaidByCustomer: number = null;
    berPaymentDate: string = null;
    transactionRef: string = null;
    docRecivedDate: string = null;
    customerNotifiedDate: string = null;
    theftTat: string = null;
    photoId: string = null;
    simBlocking: string = null;
    isFormCompletedWithSignature: string = null;
    claimDischargeVoucher: string = null;
    underwriterName: string = null;
    l1SubStatus: string = null;
    l1QueriedByUnderwriterTS: string = null;
    l1QueryResolvedDateTS: string = null;
    l1TatResponse: string = null;
    l1TatForQueryResolvedVsFinalResponse: string = null;
    l2SubmittedToUnderwriter: string = null;
    l2SubStatus: string = null;
    l2QueriedByUnderwriterTS: string = null;
    l2QueryResolvedTS: string = null;
    l2RemarksByUW: string = null;
    approvedAmnt: number = null;
    l2SubmittedToUnderwriterTime: string = null;
    l2TatResponse: string = null;
    l2TatForQueryResolvedVsFinalResponse: string = null;
    writeOffReason: string = null;
    dropOffComment: string = null;
    l1ManagerComment: string = null;
    l2ManagerComment: string = null;
    fraudRemarkComment: string = null;
    l1Investigation: string = null;
    l1VerifyRemarks: string = null;
    comment: string = null;
    claimSMSHistory: string = null;
    claimEmailHistory: string = null;
    tractingInformation: string = null;
    changeHistory: string = null;
    salesorder_no: string =null;
    comments: Comments[];
    id: string=null;
    timeOfIncident: string = null;
    lockCodeDeacription: string = null;
    l1QueryResolvedCommentByAms: string = null;
    l2ResponeFrmUnderTS: string = null;
    l2QueryComment: string = null;
    l2TatForQueryResolVsFinRes: string = null;
    fraudRemarTs: string = null;
    repairRequestStatus: string = null;
    repairApprToStrtRepai: string = null;
    repairInvoiceDt: string = null;
    repairType: string = null;
    handsetDispatchedDt: string = null;
    createdtime: string = null;
    delayInClaimIntimation: string=null;
    salvage: string=null;
    actualPickupDate: string=null;
    imei: string = null;
}