var Claim = /** @class */ (function () {
    function Claim() {
        this.claimStatus = null;
        this.status = null;
        this.aging = null;
        this.tatStartDate = null;
        this.tatEndDate = null;
        this.pickupScheduleDate = null;
        this.delieveredToArcDate = null;
        this.pickupRescheduleDate = null;
        this.rtoDate = null;
        this.handsetDispathDate = null;
        this.actualDeliveryToCustomerDate = null;
        this.tbd = null;
        this.subject = null;
        this.homeContract = null;
        this.emailId = null;
        this.completeDescription = null;
        this.incidentDate = null;
        this.incidentHappenAddress = null;
        this.damageType = null;
        this.claimType = null;
        this.claimCause = null;
        this.pickupArea = null;
        this.pickupUnit = null;
        this.pickupCity = null;
        this.pickupState = null;
        this.pickupPinCode = null;
        this.country = null;
        this.dispatchArea = null;
        this.dispatchUnit = null;
        this.dispatchCity = null;
        this.dispatchState = null;
        this.dispatchPinCode = null;
        this.dispatchCountry = null;
        this.applicableFee = null;
        this.paymentStatus = null;
        this.feePaidByCustomer = null;
        this.paymentDate = null;
        this.serviceTransactionRef = null;
        this.pickupAwbNumber = null;
        this.pickupLspName = null;
        this.pickupLspResponse = null;
        this.pickupLspRejection = null;
        this.pickupReschedule = null;
        this.pickupRescheduleCount = null;
        this.dispatchAwbNumber = null;
        this.dispatchLspName = null;
        this.dispatchLspResponse = null;
        this.dispatchLspRejection = null;
        this.dispatchReschedule = null;
        this.dispatchRescheduleCount = null;
        this.fomCoreStatus = null;
        this.arcName = null;
        this.arcAddress = null;
        this.arcCity = null;
        this.arcState = null;
        this.arcPinCode = null;
        this.arcContract = null;
        this.anyLiquidDamage = null;
        this.isDevicePoweringOn = null;
        this.isScreenCracked = null;
        this.isCosmeticDamage = null;
        this.colorOfDevice = null;
        this.memorycapacity = null;
        this.isPhoneLocked = null;
        this.lockCode = null;
        this.isRepairCompletd = null;
        this.repairStatus = null;
        this.repairInvoiceNumber = null;
        this.expectedRepairCompletedDate = null;
        this.invoiceStatus = null;
        this.actualReplacementAmount = null;
        this.revisedExpectedRepairCompletedDate = null;
        this.berStatus = null;
        this.phoneCollected = null;
        this.berApplicableFee = null;
        this.berFeePaidByCustomer = null;
        this.berPaymentDate = null;
        this.transactionRef = null;
        this.docRecivedDate = null;
        this.customerNotifiedDate = null;
        this.theftTat = null;
        this.photoId = null;
        this.simBlocking = null;
        this.isFormCompletedWithSignature = null;
        this.claimDischargeVoucher = null;
        this.underwriterName = null;
        this.l1SubStatus = null;
        this.l1QueriedByUnderwriterTS = null;
        this.l1QueryResolvedDateTS = null;
        this.l1TatResponse = null;
        this.l1TatForQueryResolvedVsFinalResponse = null;
        this.l2SubmittedToUnderwriter = null;
        this.l2SubStatus = null;
        this.l2QueriedByUnderwriterTS = null;
        this.l2QueryResolvedTS = null;
        this.l2RemarksByUW = null;
        this.approvedAmnt = null;
        this.l2SubmittedToUnderwriterTime = null;
        this.l2TatResponse = null;
        this.l2TatForQueryResolvedVsFinalResponse = null;
        this.writeOffReason = null;
        this.dropOffComment = null;
        this.l1ManagerComment = null;
        this.l2ManagerComment = null;
        this.fraudRemarkComment = null;
        this.l1Investigation = null;
        this.l1VerifyRemarks = null;
        this.comment = null;
        this.claimSMSHistory = null;
        this.claimEmailHistory = null;
        this.tractingInformation = null;
        this.changeHistory = null;
        this.salesorder_no = null;
        this.id = null;
        this.timeOfIncident = null;
        this.lockCodeDeacription = null;
        this.l1QueryResolvedCommentByAms = null;
        this.l2ResponeFrmUnderTS = null;
        this.l2QueryComment = null;
        this.l2TatForQueryResolVsFinRes = null;
        this.fraudRemarTs = null;
        this.repairRequestStatus = null;
        this.repairApprToStrtRepai = null;
        this.repairInvoiceDt = null;
        this.repairType = null;
        this.handsetDispatchedDt = null;
        this.createdtime = null;
        this.delayInClaimIntimation = null;
        this.salvage = null;
        this.actualPickupDate = null;
        this.imei = null;
    }
    return Claim;
}());
export { Claim };
