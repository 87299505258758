import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // const access_key: string = this.route.snapshot.queryParamMap.get('access_token');
    // console.log(access_key);
    // console.log(this.route.snapshot.queryParamMap);
    // console.log(this.route.snapshot.queryParams);
  }

  ngOnDestroy() {

  }
}
