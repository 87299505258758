var EventData = /** @class */ (function () {
    function EventData(eventId, messageText, formResult, contextData) {
        this.eventId = eventId ? eventId : null;
        this.messageText = messageText ? messageText : null;
        this.formResult = formResult ? formResult : {};
        this.contextData = contextData ? contextData : {};
    }
    return EventData;
}());
export { EventData };
