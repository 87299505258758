import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../../services/aws/user-registration.service";
import { UserLoginService } from "../../../services/aws/user-login.service";
import { NewPasswordUser } from "../../../models/aws.model";

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './newPassword.component.html'
})
export class NewPasswordComponent implements OnInit {
    registrationUser: NewPasswordUser;
    router: Router;
    errorMessage: string;

    constructor(public userRegistration: UserRegistrationService, public userService: UserLoginService, router: Router) {
        this.router = router;
        this.registrationUser = new NewPasswordUser();
        this.errorMessage = null;
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated((isValidSession) => {
            if (isValidSession) {
                this.router.navigate(['/pg/newPassword']);
            }
        });
    }

    onRegister() {
        console.log(this.registrationUser);
        this.errorMessage = null;
        this.userRegistration.newPassword(this.registrationUser, (result, error) => {
            if (error) {
                this.errorMessage = error;
                return;
            }
            if (result) {
                this.router.navigate(['/pg/newPassword']);
            }
        });
    }

    // cognitoCallback(message: string, result: any) {
    //     if (message != null) { //error
    //         this.errorMessage = message;
    //         console.log("result: " + this.errorMessage);
    //     } else { //success
    //         //move to the next step
    //         console.log("redirecting");
    //         this.router.navigate(['/pg/home']);
    //     }
    // }

    // isLoggedIn(message: string, isLoggedIn: boolean) {
    //     if (isLoggedIn)
    //         this.router.navigate(['/pg/home']);
    // }
}
