import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var AppComponent = /** @class */ (function () {
    function AppComponent(route) {
        this.route = route;
        this.title = 'app';
    }
    AppComponent.prototype.ngOnInit = function () {
        // const access_key: string = this.route.snapshot.queryParamMap.get('access_token');
        // console.log(access_key);
        // console.log(this.route.snapshot.queryParamMap);
        // console.log(this.route.snapshot.queryParams);
    };
    AppComponent.prototype.ngOnDestroy = function () {
    };
    return AppComponent;
}());
export { AppComponent };
