<!-- begin:: Page -->
<div class="m-grid m-grid--hor m-grid--root m-page">
   <!-- BEGIN: Header -->
   <header id="m_header" class="m-grid__item    m-header ">
      <div class="m-container m-container--fluid m-container--full-height">
         <div class="m-stack m-stack--ver m-stack--desktop">
            <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
               <div class="row">
                  <div class="col-md-3">
                     <!-- BEGIN: Horizontal Menu -->
                     <div id="m_header_menu" class="m-header-menu">
                        <ul class="m-menu__nav">
                           <li class="m-menu__item"><img src="assets/images/AMS-Logo.png" class="ams-logo"></li>
                        </ul>
                     </div>
                     <!-- END: Horizontal Menu -->
                  </div>
                  <div class="col-md-8">
                     <div id="m_header_menu" class="m-header-menu pull-right-hard">
                        <ul class="m-menu__nav">
                           <li class="m-menu__item">Client Name: <br><span>{{ selectedClientName }}</span></li>
                           <li class="m-menu__item">Customer Name: <br><span>{{ searchResult.customerInfo.name }}</span></li>
                           <li class="m-menu__item">Policy No: <br><span>{{ selectedPolicyNo }}</span></li>
                           <li class="m-menu__item">Mobile No: <br><span>{{ searchResult.customerInfo.mobileNo }}</span></li>
                           <li class="m-menu__item">IMEI No:<br>
                              <span
                                 *ngIf="searchResult != null && searchResult.policies != null && searchResult.policies.length > 0">
                                 <span>{{ searchResult.customerInfo.imei != null && searchResult.customerInfo.imei.trim().length != 0 ? searchResult.customerInfo.imei : textToBeSearch }}</span>
                              </span>
                           </li>
                           <li class="m-menu__item">
                              <span class="m-list-search__form-input-wrapper">
                                 <!-- <input id="m_quicksearch_input" style="color: gray;" type="text" name="q"
                                    class="m-list-search__form-input" value="" placeholder="Find IMEI No."
                                    [(ngModel)]="textToBeSearch" (keydown.enter)="searchText()"> -->
                              </span>
                           </li>
                           <!-- <li class="m-menu__item">
                              <a href="#" class="m-nav__link" (click)="searchText()">
                                 <span id="m_quicksearch" class="m-nav__link-icon"><i class="fas fa-search"></i></span>
                              </a>
                           </li> -->

                           <li class="m-menu__item" style="width: 80px"><span class="fixed-time-column-width"
                                 style="font-weight: bold;font-size: 18px">
                                 {{minuteadd}}{{minutes}}:{{secondadd}}{{timeLeft}}</span>
                           </li>
                           <li class="m-menu__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                              m-dropdown-toggle="click">

                              <a href="#" class="m-nav__link m-dropdown__toggle">
                                 <span class="m-topbar__userpic">
                                    My Account
                                    <img src="https://keenthemes.com/metronic/preview/assets/app/media/img/users/user4.jpg"
                                       class="m--img-rounded m--marginless" alt="" />
                                 </span>
                              </a>
                              <div class="m-dropdown__wrapper">
                                 <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                 <div class="m-dropdown__inner">
                                    <div class="m-dropdown__header m--align-center">
                                       <div class="m-card-user m-card-user--skin-dark">
                                          <div class="m-card-user__details">
                                             <span class="m-card-user__name m--font-weight-500">Welcome<br>{{username}}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="m-dropdown__body">
                                       <div class="m-dropdown__content">
                                          <ul class="m-nav">
                                             <!--<li class="m-nav__item">
                                                   <a href="#" class="m-nav__link">
                                                      <i class="m-nav__link-icon fas fa-user-tie"></i>
                                                      <span class="m-nav__link-title">
                                                         <span class="m-nav__link-wrap">
                                                            <span class="m-nav__link-text">Last Login</span>
                                                         </span>
                                                      </span>
                                                   </a>
                                                </li>-->
                                             <!--<li class="m-nav__item">
                                                   <a href="#" class="m-nav__link">
                                                      <i class="m-nav__link-icon fas fa-unlock-alt"></i>
                                                      <span class="m-nav__link-text"><button *ngIf="userLoggedIn" (click)="cognitoChangePwd()">Change Password</button></span>
                                                   </a>
                                                </li> -->
                                             <li class="m-nav__item">
                                                <a href="#" class="m-nav__link">
                                                   <i class="m-nav__link-icon fas fa-sign-out-alt"></i>
                                                   <span class="m-nav__link m--font-weight-500" *ngIf="userLoggedIn"
                                                      (click)="cognitoSignOut()">Sign Out</span>
                                                </a>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-md-1">
                     <div id="m_header_topbar"
                        class="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid pull-right-hard">
                        <div class="m-stack__item m-topbar__nav-wrapper">
                           <ul class="m-topbar__nav m-nav m-nav--inline">
                              <li class="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light	m-list-search m-list-search--skin-light"
                                 m-dropdown-toggle="click" id="m_quicksearch" m-quicksearch-mode="dropdown"
                                 m-dropdown-persistent="1">
                                 <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <!-- <span class="menu-find">Find</span> <span class="m-nav__link-icon"><i class="fas fa-search"></i></span> -->
                                 </a>
                                 <div class="m-dropdown__wrapper">
                                    <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
                                    <div class="m-dropdown__inner ">
                                       <div class="m-dropdown__header">
                                          <form class="m-list-search__form">
                                             <div class="m-list-search__form-wrapper">
                                                <span class="m-list-search__form-input-wrapper">
                                                   <input id="m_quicksearch_input" type="text" name="q"
                                                      class="m-list-search__form-input" value=""
                                                      placeholder="Find by Mobile No. / IMEI No." [(ngModel)]="textToBeSearch"
                                                      (keydown.enter)="searchText()">
                                                </span>
                                                <span class="m-list-search__form-icon-close" id="m_quicksearch_close">
                                                   <i class="la la-remove"></i>
                                                </span>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                 </div>
                              </li>

                           </ul>
                        </div>
                     </div>
                  </div>
                  <!-- END: Topbar -->
                  <!-- END: Topbar -->
               </div>
            </div>
         </div>
      </div>
   </header>
   <!-- END: Header -->
   <!-- begin::Body -->
   <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
      <ngx-spinner type="line-spin-clockwise-fade">Loading..</ngx-spinner>
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
         <div class="m-content">
            <div class="row">
               <div class="col-7 col-lg-7 col-md-7 col-sm-7 col-xl-7" style="height: 480px; overflow: auto;">
                  <!--begin::Portlet-->
                  <div *ngIf="searchFl" class="m-portlet m-portlet--tabs">
                     <div class="m-portlet__body">
                        <div *ngIf="data_not_found" style="align-items: center">
                           <h5 class="m-form__heading-title" style="font: bold; color: crimson">No Data found for
                              entered search text in CRM</h5>
                        </div>
                        <div *ngIf="fetchCRMError" style="align-items: center">
                           <h5 class="m-form__heading-title" style="font: bold; color: crimson">Error occured while
                              fetching data from CRM. Please Refresh</h5>
                        </div>
                        <div
                           *ngIf="searchResult != null && searchResult.policies != null && searchResult.policies.length > 0"
                           class="mr-auto">
                           <!-- <h4 class="m-subheader__title m-subheader__title--separator">Checklist for Policy
                              {{searchResult.policies[policyIndex].salesorder_no}}</h4> -->
                           <ul class="m-subheader__breadcrumbs m-nav m-nav--inline checklist">
                              <li *ngIf="searchResult.policies[policyIndex].isPolicyInForce != null"
                                 class="m-nav__item">
                                 <span
                                    [class]="searchResult.policies[policyIndex].isPolicyInForce?  'label quadrat' : 'label label-success' ">
                                    Policy in force </span>
                              </li>
                              <li *ngIf="searchResult.policies[policyIndex].claims_exhausted != null"
                                 class="m-nav__item">
                                 <div *ngIf="searchResult.policies[policyIndex].claims_exhausted; else benefittemplate"
                                    class="quadrat label">
                                    <span>
                                       2 Request Registered </span>
                                 </div>
                                 <div *ngIf="searchResult.policies[policyIndex].total_claims==1">
                                    <span [class]="'label label-warning'">
                                       1 Request Registered </span>
                                 </div>
                                 <div *ngIf="searchResult.policies[policyIndex].total_claims==0">
                                    <span [class]="'label label-success'">
                                       0 Request Registered </span>
                                 </div>
                              </li>
                              <li *ngIf="searchResult.policies[policyIndex].policy_benefit_exhausted != null && !searchResult.policies[policyIndex].claims_exhausted"
                                 class="m-nav__item">
                                 <div *ngIf="searchResult.policies[policyIndex].policy_benefit_exhausted"
                                    class="quadrat label">
                                    <span>
                                       <i class="fa fa-rupee" style="font-size:13px"></i> 0 remaining </span>
                                 </div>
                                 <div
                                    *ngIf="searchResult.policies[policyIndex].policy_benefit_available_color=='amber'">
                                    <span [class]="'label label-warning'">
                                       <i class="fa fa-rupee" style="font-size:13px"></i>
                                       {{searchResult.policies[policyIndex].policy_benefit_available}} Remaining </span>
                                 </div>
                                 <div
                                    *ngIf="searchResult.policies[policyIndex].policy_benefit_available_color=='green'">
                                    <span [class]="'label label-success'">
                                       <i class="fa fa-rupee" style="font-size:13px"></i>
                                       {{searchResult.policies[policyIndex].policy_benefit_available}} Remaining </span>
                                 </div>
                              </li>
                              <li *ngIf="searchResult.policies[policyIndex].claimsExpiredFl != null"
                                 class="m-nav__item">
                                 <div *ngIf="searchResult.policies[policyIndex].claimsExpiredFl;else elsepart; "
                                    class="quadrat label">
                                    <span>
                                       Policy Expired</span>
                                 </div>
                                 <ng-template #elsepart>
                                    <div class="case">
                                       <span [class]="'label label-success'">
                                          Policy Active </span>
                                    </div>
                                 </ng-template>
                              </li>
                              <li *ngIf="searchResult.policies[policyIndex].status != null" class="m-nav__item">
                                 <div *ngIf="searchResult.policies[policyIndex].status=='Current'">
                                    <span [class]="'label label-success'">
                                       Policy Status- Current</span>
                                 </div>
                                 <div *ngIf="searchResult.policies[policyIndex].status=='New'">
                                    <span [class]="'label label-success'">
                                       Policy Status- New</span>
                                 </div>
                                 <div *ngIf="searchResult.policies[policyIndex].status=='Lapsed'" class="quadrat label">
                                    <span>
                                       Policy Status- Lapsed</span>
                                 </div>
                                 <div *ngIf="searchResult.policies[policyIndex].status=='Declined'"
                                    class="quadrat label">
                                    <span>
                                       Policy Status- Declined</span>
                                 </div>


                              </li>


                              <li *ngIf="searchResult.policies[policyIndex].clientName != null" class="m-nav__item">
                                 <span [class]="'label label-success'">
                                    {{searchResult.policies[policyIndex].clientName}}</span>
                              </li>

                              <li *ngIf="searchResult.policies[policyIndex].ragScore != null" class="m-nav__item">
                                 <span
                                    [class]="searchResult.policies[policyIndex].ragScore > 70 ?  'label label-danger' : 'label label-success' ">
                                    Claim RAG score {{ searchResult.policies[policyIndex].ragScore }}</span>
                              </li>
                              <li *ngIf="searchResult.policies[policyIndex].storeLocationScore != null"
                                 class="m-nav__item">
                                 <span
                                    [class]="searchResult.policies[policyIndex].storeLocationScore > 70 ?  'label label-danger' : 'label label-success' ">
                                    Store score {{ searchResult.policies[policyIndex].storeLocationScore }}</span>
                              </li>
                           </ul>
                        </div>

                        <hr>
                        <ul class="nav nav-tabs" role="tablist">
                           <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_1_tab_content"
                                 role="tab">
                                 <i class="fas fa-user"></i> Customer
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link active  " data-toggle="tab" href="#m_portlet_base_demo_1_2_tab_content"
                                 role="tab">
                                 <i class="fas fa-file-alt"></i> Contract
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_3_tab_content"
                                 role="tab">
                                 <i class="fas fa-file-invoice"></i> Service Request
                              </a>
                           </li>
                           <!--<li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_4_tab_content"
                                 role="tab">
                                 <i class="fas fa-cog"></i> Activities
                              </a>
                           </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_5_tab_content" role="tab">
                              <i class="fas fa-check-circle"></i> Checklist
                              </a>
                              </li> 
                           <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_6_tab_content"
                                 role="tab">
                                 <i class="fas fa-file-signature"></i> Notes
                              </a>
                           </li>-->
                        </ul>
                        <div class="tab-content">
                           <div class="tab-pane" id="m_portlet_base_demo_1_1_tab_content" role="tabpanel">
                              <div class="m-form__section">
                                 <div
                                    *ngIf="searchResult.customerInfo != null && searchResult.customerInfo.name != null">
                                    <div class="row">
                                       <label class="col-xl-6 col-lg-6 col-form-label"><b>Name :</b>
                                          {{ searchResult.customerInfo.name }}</label>
                                       <label class="col-xl-6 col-lg-6 col-form-label"><b>Email Id :</b>
                                          {{ searchResult.customerInfo.emailId }}</label>
                                    </div>
                                    <div class="row">
                                       <label class="col-xl-6 col-lg-6 col-form-label"><b>Alternate Phone No. :</b>
                                          {{ searchResult.customerInfo.alternatePhoneNo }}</label>
                                       <label class="col-xl-6 col-lg-6 col-form-label"><b>IMEI No. :</b>
                                          {{ textToBeSearch }}</label>
                                    </div>

                                    <div class="m-separator m-separator--dashed m-separator--lg"></div>

                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane active" id="m_portlet_base_demo_1_2_tab_content" role="tabpanel">
                              <div class="m-form__section">
                                 <ul class="nav nav-tabs" role="tablist">
                                    <li *ngFor="let result of searchResult.policies; let index=index"
                                       class="nav-item m-tabs__item"
                                       (click)="onPolicyClick(index,result.salesorder_no)">
                                       <a *ngIf="index == 0" class="nav-link m-tabs__link active" data-toggle="tab"
                                          [href]="'#m_tabs_policy_6_'+ index" role="tab">{{ result.salesorder_no }}
                                          {{result.policyType}}</a>
                                       <a *ngIf="index !=0" class="nav-link m-tabs__link" data-toggle="tab"
                                          [href]="'#m_tabs_policy_6_'+ index" role="tab">{{ result.salesorder_no }}
                                          {{result.policyType}}</a>
                                    </li>
                                 </ul>
                                 <div class="tab-content">
                                    <div *ngFor="let result of searchResult.policies; let index=index"
                                       [class]="index == 0 ? 'tab-pane active' : 'tab-pane'"
                                       [id]="'m_tabs_policy_6_' + index" role="tabpanel">

                                       <h5 class="m-form__heading-title">Verification Details:</h5>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Name :</b>
                                             {{ searchResult.customerInfo.name }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Make :</b>
                                             {{ result.make }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Email Id :</b>
                                             {{ result.email }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Model :</b>
                                             {{ result.model }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Telephone Number :</b>
                                             {{result.phoneNumber}}
                                          </label>

                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Purchase Date :</b>

                                             {{ result.mobilePurchaseDate | date:'dd-MMMM-yyyy' }}</label>

                                       </div>
                                       <div class="row">
                                          <label class="col-xl-12 col-lg-12 col-form-label"><b>Address :</b>
                                             {{result.customerStreeAddress}}
                                          </label>
                                       </div>
                                       <div class="m-separator m-separator--dashed m-separator--lg"></div>
                                       <h5 class="m-form__heading-title">Policy Details:</h5>

                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Start Date :</b>

                                             {{ result.startDate | date:'dd-MMMM-yyyy' }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>End Date :</b>
                                             <span *ngIf="isDateLessThanToday(result.endDate); else endDate"
                                                class="label quadrat">
                                                {{ result.endDate | date:'dd-MMMM-yyyy' }}</span>
                                             <ng-template #endDate>
                                                <span *ngIf="lessThanThirtyDays(result.endDate)"
                                                   class="label label-warning">
                                                   {{ result.endDate | date:'dd-MMMM-yyyy' }}</span>
                                             </ng-template>
                                             <span *ngIf="!lessThanThirtyDays(result.endDate)">
                                                {{ result.endDate | date:'dd-MMMM-yyyy' }}</span>
                                          </label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Policy Type :</b>
                                             {{ result.policyType }}
                                          </label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Program Name :</b>
                                             {{ result.programType }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Policy Status :</b>
                                             <span
                                                *ngIf="isPolicyStatusOtherThanCurrent(result.status) ; else policyStatus"
                                                class="quadrat label">
                                                {{ result.status }}
                                             </span>
                                             <ng-template #policyStatus>{{result.status}}</ng-template>
                                          </label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Excess Fee :</b>
                                             <span class="label label-warning"> <i class="fa fa-rupee"
                                                   style="font-size:13px"></i>{{ result.excessFee }} </span></label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Device Category :</b>
                                             <span
                                                *ngIf="result.device_category=='Please select category' ; else deviceCategory">

                                             </span>
                                             <ng-template #deviceCategory>{{result.device_category }}</ng-template>
                                          </label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>AMS Policy Id :</b>
                                             {{ result.salesorder_no }}
                                          </label>

                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Device Cost :</b>
                                             <i class="fa fa-rupee" style="font-size:13px"></i>
                                             {{ result.costMobile }}
                                          </label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Balance Cover :</b>
                                             <i class="fa fa-rupee" style="font-size:13px"></i>
                                             {{result.totalBenefit-result.repair_cost}} /
                                             {{ result.totalBenefit }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Client Name :</b>
                                             {{ result.clientName }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Underwriter Name :</b>
                                             {{ result.underwriterName }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Purchased On :</b>

                                             {{ result.mobilePurchaseDate | date:'dd-MMMM-yyyy' }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Premium Paid :</b>
                                             <i class="fa fa-rupee" style="font-size:13px"></i>
                                             {{ result.premiumPaid }}
                                          </label>
                                       </div>
                                   <!-- Activity information starts here -->
                                   <div class="m-accordion m-accordion--default activity-tab" id="m_accordion_00" role="tablist">
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_00_item_00_head" data-toggle="collapse"
                                             href="#m_accordion_00_item_00_body" aria-expanded="false">
                                             <span class="m-accordion__item-title">Activity Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_00_item_00_body"
                                             role="tabpanel" aria-labelledby="m_accordion_00_item_00_head"
                                             data-parent="#m_accordion_00">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                      <table class="table">
                                                            <thead>
                                                            <tr>
                                                               <th style="width: 20%" (click)="sorted()">Date/time <i [className]="sort==0 ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i></th>
                                                               <th style="width: 20%">Description</th>
                                                               <th style="width: 20%">Assigned To</th>
                                                               <th style="width: 20%">Status</th>
                                                               <th style="width: 20%">Priority</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <div class="row"
                                                                  *ngFor="let comm of result.comments| orderBy:'-createdDt'">
                                                            <tr class="active">
                                                               <td style="width: 20%">{{ comm.createdDt | date:'dd-MMMM-yyyy HH:MM' }}</td>
                                                               <td style="width: 20%">{{ comm.description }}</td>
                                                               <td style="width: 20%">{{ comm.assignedTo }}</td>
                                                               <td style="width: 20%">{{ comm.status }}</td>
                                                               <td style="width: 20%">{{ comm.priority }}</td>
                                                            </tr>
                                                            </div>
                                                            </tbody>
                                                         </table>
                                                         
                                                         <div class="add-active">
                                                            <form>
                                                               <button class="btn btn-lg btn-add" (click)="activity()">Add Activity</button>
                                                               <div class="activity" *ngIf="active==1">
                                                                  <div class="row">
                                                                     <div class="col-md-2">
                                                                        <label>Call Type</label>
                                                                     </div>
                                                                     <div class="col-md-4">
                                                                        <div class="form-group">
                                                                           <select class="form-control" name="callType" [(ngModel)]="commentsFormcallType">
                                                                              <option  ng-value="Airway bill not received" >Airway bill not received</option>
                                                                              <option  ng-value="Amtrust Call Disconnected - Non Verified" >Amtrust Call Disconnected - Non Verified</option>
                                                                           </select>
                                                                        </div>
                                                                     </div>
                                                                     <div class="col-md-2">
                                                                           <label>Priority</label>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                           <div class="form-group">
                                                                              <select class="form-control" name="callType" [(ngModel)]="commentsFormpriority">
                                                                                 <option  ng-value="Low" >Low</option>
                                                                                 <option  ng-value="Medium" >Medium</option>
                                                                                 <option  ng-value="High" >High</option>
                                                                              </select>
                                                                           </div>
                                                                        </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-2">
                                                                        <label>Description</label>
                                                                     </div>
                                                                     <div class="col-md-10">
                                                                        <div class="form-group">
                                                                           <textarea class="form-control" rows="6"  name="description" [(ngModel)]="commentsFormdescription"></textarea>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-6">
                                                                           <div class="row">
                                                                              <div class="col-md-4">
                                                                                 <label>Assigned to</label>
                                                                              </div>
                                                                              <div class="col-md-8">
                                                                                 <div class="form-group">
                                                                                       <select class="form-control" name="callType" [(ngModel)]="commentsFormassignTo">
                                                                                             <option  ng-value="Underwriter" >Underwriter</option>
                                                                                             <option  ng-value="Logistics" >Logistics</option>
                                                                                             <option  ng-value="Support" >Support</option>
                                                                                             <option  ng-value="Replacement" >Replacement</option>
                                                                                             <option  ng-value="Post Repair" >Post Repair</option>
                                                                                             <option  ng-value="Repair" >Repair</option>
                                                                                             <option  ng-value="Internal Escalation Team" >Internal Escalation Team</option>
                                                                                             <option  ng-value="Sales Team" >Sales Team</option>
                                                                                             <option  ng-value="Linguistic Calls" >Linguistic Calls</option>
                                                                                             <option  ng-value="Laptop Claims" >Laptop Claims</option>
                                                                                          </select>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                     </div>
                                                                     <div class="col-md-6">
                                                                           <div class="row">
                                                                              <div class="col-md-4">
                                                                                 <label>Status</label>
                                                                              </div>
                                                                              <div class="col-md-8">
                                                                                 <div class="form-group">
                                                                                       <select class="form-control" name="callType" [(ngModel)]="commentsFormstatus">
                                                                                             <option  ng-value="Pending" >Pending</option>
                                                                                             <option  ng-value="In Progress" >In Progress</option>
                                                                                             <option  ng-value="Complete" >Complete</option>
                                                                                             <option  ng-value="Close" >Close</option>
                                                                                             <option  ng-value="Claim Settled" >Claim Settled</option>
                                                                                       </select>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-12">
                                                                        <div class="active-btn">
                                                                           <button class="btn btn-primary btn-clear" type="reset">Clear</button>
                                                                           <button class="btn btn-success btn-in" (click)="savePolicyComment(result.id)">Add</button>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </form>
                                                        </div>
                                                
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                    </div>
                                    <!-- Activity information ends here -->
                                   
                                   
                                    </div>


                                    



                                    <!--Policy Tab2-->
                                    <!--End Policy Tab2-->
                                 </div>
                              </div>
                           </div>
                           <!--Service Request start-->
                           <div class="tab-pane" id="m_portlet_base_demo_1_3_tab_content" role="tabpanel">
                              <div class="m-form__section">

                                 <ul class="nav nav-tabs" role="tablist">
                                    <li *ngFor="let result of claimResult; let index=index"
                                       class="nav-item m-tabs__item">
                                       <a *ngIf="index == 0" class="nav-link m-tabs__link active" data-toggle="tab"
                                          [href]="'#m_tabs_claim_6_'+ index" role="tab">
                                          {{ result.arcContract }}</a>
                                       <a *ngIf="index !=0" class="nav-link m-tabs__link" data-toggle="tab"
                                          [href]="'#m_tabs_claim_6_'+ index" role="tab">
                                          {{ result.arcContract }}</a>
                                    </li>
                                 </ul>
                              </div>
                              <div class="tab-content">
                                 <div *ngFor="let claim of searchResult.claims; let index=index"
                                    [class]="index == 0 ? 'tab-pane active' : 'tab-pane'"
                                    [id]="'m_tabs_claim_6_' + index" role="tabpanel">
                                    <!--begin claim 01-->

                                    <!-- <aw-wizard>
                                       <aw-wizard-step stepTitle="Reported">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="L1 Approval">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="Documents">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="Pickup">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="L2 Approval">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="Repair">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="Dispatched">

                                       </aw-wizard-step>
                                       <aw-wizard-step stepTitle="Delivered ">

                                       </aw-wizard-step>
                                    </aw-wizard> -->
                                    <br>
                                    <div class="m-accordion m-accordion--default" id="m_accordion_1" role="tablist">
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_1_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_1_body" aria-expanded="false">
                                             <span class="m-accordion__item-title">Status</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_1_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_1_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">


                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Status
                                                            :</b>
                                                         {{ claim.claimStatus }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Status :</b>
                                                         {{ claim.status }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Aging :</b>
                                                         {{ claim.aging }} </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>TAT Start Date
                                                            :</b>

                                                         {{ claim.tatStartDate | date:'dd-MMMM-yyyy' }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>TAT End Date
                                                            :</b>

                                                         {{ claim.tatEndDate | date:'dd-MMMM-yyyy' }} </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Schedule
                                                            Date :</b>
                                                         {{ claim.pickupScheduleDate | date:'dd-MMMM-yyyy' }}
                                                      </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Delivered to
                                                            ARC
                                                            Date :</b>

                                                         {{ claim.delieveredToArcDate | date:'dd-MMMM-yyyy' }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                            Reschedule
                                                            Date :</b>

                                                         {{ claim.pickupRescheduleDate | date:'dd-MMMM-yyyy' }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>RTO/Lost Date
                                                            :</b>
                                                         {{ claim.rtoDate | date:'dd-MMMM-yyyy' }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Handset
                                                            Dispatch
                                                            Date :</b>

                                                         {{ claim.handsetDispathDate | date:'dd-MMMM-yyyy' }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Actual Delivery
                                                            to
                                                            customer Date :</b>

                                                         {{ claim.actualDeliveryToCustomerDate | date:'dd-MMMM-yyyy' }}
                                                      </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_20_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_20_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Comments Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_20_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_20_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   
                                                   <!-- Activity information starts here -->
                                                  
                                                         <!--begin::Item-->
                                                         
                                                                     <table class="table">
                                                                        <thead>
                                                                        <tr>
                                                                           <th style="width: 50%" (click)="sorted()">Date/time <i [className]="sort==0 ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i></th>
                                                                           <th style="width: 50%">Description</th>
                                                                           
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              <div class="row"
                                                                              *ngFor="let comm of claim.comments| orderBy:'-createdtime'">
                                                                        <tr class="active">
                                                                           <td>{{ comm.createdtime | date:'dd-MMMM-yyyy HH:MM' }}</td>
                                                                           <td>{{ comm.commentcontent }}</td>
                                                                           <td>{{ comm.assignedTo }}</td>
                                                                           <td>{{ comm.status }}</td>
                                                                           <td>{{ comm.priority }}</td>
                                                                        </tr>
                                                                        </div>
                                                                        </tbody>
                                                                     </table>
                                                                     
                                                                     <div class="add-active">
                                                                        <form>
                                                                           <button class="btn btn-lg btn-add" (click)="activity()">Add Activity</button>
                                                                           <div class="activity" *ngIf="active==1">
                                                                              <div class="row">
                                                                                 <div class="col-md-2">
                                                                                    <label>Call Type</label>
                                                                                 </div>
                                                                                 <div class="col-md-4">
                                                                                    <div class="form-group">
                                                                                       <select class="form-control" name="callType" [(ngModel)]="commentsFormcallType">
                                                                                          <option  ng-value="Airway bill not received" >Airway bill not received</option>
                                                                                          <option  ng-value="Amtrust Call Disconnected - Non Verified" >Amtrust Call Disconnected - Non Verified</option>
                                                                                       </select>
                                                                                    </div>
                                                                                 </div>
                                                                                 <div class="col-md-2">
                                                                                       <label>Priority</label>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                       <div class="form-group">
                                                                                          <select class="form-control" name="callType" [(ngModel)]="commentsFormpriority">
                                                                                             <option  ng-value="Low" >Low</option>
                                                                                             <option  ng-value="Medium" >Medium</option>
                                                                                             <option  ng-value="High" >High</option>
                                                                                          </select>
                                                                                       </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="row">
                                                                                 <div class="col-md-2">
                                                                                    <label>Description</label>
                                                                                 </div>
                                                                                 <div class="col-md-10">
                                                                                    <div class="form-group">
                                                                                       <textarea class="form-control" rows="6"  name="description" [(ngModel)]="commentsFormdescription"></textarea>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="row">
                                                                                 <div class="col-md-6">
                                                                                       <div class="row">
                                                                                          <div class="col-md-4">
                                                                                             <label>Assigned to</label>
                                                                                          </div>
                                                                                          <div class="col-md-8">
                                                                                             <div class="form-group">
                                                                                                   <select class="form-control" name="callType" [(ngModel)]="commentsFormassignTo">
                                                                                                         <option  ng-value="Underwriter" >Underwriter</option>
                                                                                                         <option  ng-value="Logistics" >Logistics</option>
                                                                                                         <option  ng-value="Support" >Support</option>
                                                                                                         <option  ng-value="Replacement" >Replacement</option>
                                                                                                         <option  ng-value="Post Repair" >Post Repair</option>
                                                                                                         <option  ng-value="Repair" >Repair</option>
                                                                                                         <option  ng-value="Internal Escalation Team" >Internal Escalation Team</option>
                                                                                                         <option  ng-value="Sales Team" >Sales Team</option>
                                                                                                         <option  ng-value="Linguistic Calls" >Linguistic Calls</option>
                                                                                                         <option  ng-value="Laptop Claims" >Laptop Claims</option>
                                                                                                      </select>
                                                                                             </div>
                                                                                          </div>
                                                                                       </div>
                                                                                 </div>
                                                                                 <div class="col-md-6">
                                                                                       <div class="row">
                                                                                          <div class="col-md-4">
                                                                                             <label>Status</label>
                                                                                          </div>
                                                                                          <div class="col-md-8">
                                                                                             <div class="form-group">
                                                                                                   <select class="form-control" name="callType" [(ngModel)]="commentsFormstatus">
                                                                                                         <option  ng-value="Pending" >Pending</option>
                                                                                                         <option  ng-value="In Progress" >In Progress</option>
                                                                                                         <option  ng-value="Complete" >Complete</option>
                                                                                                         <option  ng-value="Close" >Close</option>
                                                                                                         <option  ng-value="Claim Settled" >Claim Settled</option>
                                                                                                   </select>
                                                                                             </div>
                                                                                          </div>
                                                                                       </div>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="row">
                                                                                 <div class="col-md-12">
                                                                                    <div class="active-btn">
                                                                                       <button class="btn btn-primary btn-clear" type="reset">Clear</button>
                                                                                       <button class="btn btn-success btn-in" (click)="saveComment(claim.id)">Add</button>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </form>
                                                                    </div>
                                                            
                                                         
                                                         <!--end::Item-->
                                                      
                                                      <!-- Activity information ends here -->


                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_2_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_2_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Claim Documents </span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_2_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_2_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>TBD :</b>
                                                         {{ claim.tbd }}</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_3_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_3_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Claim Intimation
                                                Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_3_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_3_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Subject
                                                            :</b>
                                                         {{ claim.subject }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Home Contact
                                                            :</b>
                                                         {{ claim.homeContract }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Email :</b>
                                                         {{ claim.emailId }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Complete
                                                            Description :</b>

                                                         {{ claim.completeDescription }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Date of
                                                            Incident

                                                            :</b>
                                                         {{ claim.incidentDate | date:'dd-MMMM-yyyy' }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Time of
                                                            Incident

                                                            :</b>
                                                         {{ claim.timeOfIncident }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Damage Type
                                                            :</b>
                                                         {{ claim.damageType }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Type
                                                            :</b>
                                                         {{ claim.claimType }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Claim Cause
                                                            :</b>
                                                         {{ claim.claimCause }}</label>
                                                   </div>
                                                   
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_4_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_4_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Address Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_4_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_4_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup
                                                            Street/Area :</b>
                                                         {{ claim.pickupArea }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup Unit #
                                                            /
                                                            Floor / House no. / Block / Landmark :</b>
                                                         {{ claim.pickupUnit }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup City
                                                            :</b>
                                                         {{ claim.pickupCity }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup State
                                                            :</b>
                                                         {{ claim.pickupState }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Pin Code
                                                            :</b>
                                                         {{ claim.pickupPinCode }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Country
                                                            :</b>
                                                         {{ claim.pickupCountry }}</label>
                                                   </div>
                                                   <hr>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                            Street/Area :</b>
                                                         {{ claim.dispatchArea }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch Unit
                                                            #
                                                            / Floor / House no. / Block / Landmark :</b>
                                                         {{ claim.dispatchUnit }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch City
                                                            :</b>
                                                         {{ claim.dispatchCity }} </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch State
                                                            :</b>
                                                         {{ claim.dispatchState }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch Pin
                                                            Code
                                                            :</b>
                                                         {{ claim.dispatchPinCode }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                            Country
                                                            :</b>
                                                         {{ claim.dispatchCountry }}</label>
                                                   </div>

                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_5_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_5_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Service Fee Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_5_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_5_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Applicable
                                                               Fee
                                                               :</b>
                                                            <i class="fa fa-rupee" style="font-size:13px"></i>
                                                            {{ claim.applicableFee }}
                                                         </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment
                                                               status
                                                               :</b>
                                                            {{ claim.paymentStatus }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Fee paid by
                                                               customer :</b>
                                                            {{ claim.feePaidByCustomer }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment date
                                                               :</b>

                                                            {{ claim.paymentDate | date:'dd-MMMM-yyyy' }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Transaction
                                                               reference :</b>
                                                            {{ claim.transactionRef }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_6_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_6_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Logistic Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_6_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_6_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup AWB
                                                               Number :</b>
                                                            {{ claim.pickupAwbNumber }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup LSP
                                                               Name :</b>
                                                            {{ claim.pickupLspName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup LSP
                                                               Rejection reason :</b>
                                                            {{ claim.pickupLspRejection }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                               Rescheduled
                                                               :</b>
                                                            {{ claim.pickupReschedule }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                               Reschedule
                                                               count :</b>
                                                            {{ claim.pickupRescheduleCount }} </label>
                                                      </div>
                                                      <hr>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                               AWB
                                                               Number :</b>
                                                            {{ claim.dispatchAwbNumber }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                               LSP
                                                               Name :</b>
                                                            {{ claim.dispatchLspName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                               LSP
                                                               Response :</b>
                                                            {{ claim.dispatchLspResponse }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                               LSP
                                                               Rejection reason :</b>
                                                            {{ claim.dispatchLspRejection }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                               Rescheduled :</b>
                                                            {{ claim.dispatchReschedule }} </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                               Reschedule count :</b>

                                                            {{ claim.dispatchRescheduleCount }}</label>
                                                      </div>
                                                      <hr>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>FOM Core
                                                               Status
                                                               :</b>
                                                            {{ claim.fomCoreStatus }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_7_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_7_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">ARC Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_7_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_7_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>ARC Name
                                                               :</b>
                                                            {{ claim.arcName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>ARC
                                                               Address
                                                               :</b>
                                                            {{ claim.arcAddress }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC City
                                                               :</b>
                                                            {{ claim.arcCity }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC state
                                                               :</b>
                                                            {{ claim.arcState }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC PIN code
                                                               :</b>
                                                            {{ claim.arcPinCode }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC Contact
                                                               :</b>
                                                            {{ claim.arcContact }} </label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_8_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_8_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Damage Questionnaire</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_8_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_8_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Any Liquid
                                                            Damage
                                                            :</b>
                                                         {{ claim.anyLiquidDamage }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the device
                                                            powering on? :</b>
                                                         {{ claim.isScreenCracked }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the screen
                                                            cracked? :</b>
                                                         {{ claim.isScreenCracked }} </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>* Are there any
                                                            other
                                                            cosmetic damages on the back panel or other areas? :</b>
                                                         {{ claim.isCosmeticDamage }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>What is the
                                                            color
                                                            of
                                                            the device? :</b>
                                                         {{ claim.colorOfDevice }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>What is the
                                                            memory
                                                            capacity? :</b>
                                                         {{ claim.memorycapacity }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the phone
                                                            locked?
                                                            :</b>
                                                         {{ claim.isPhoneLocked }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Lock Code
                                                            :</b>
                                                         {{ claim.lockCode }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Description :</b>

                                                         {{ claim.lockCodeDeacription }}</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_9_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_9_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Repair</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_9_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_9_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Completed :</b>

                                                            {{ claim.isRepairCompletd }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Status
                                                               :</b>
                                                            {{ claim.repairStatus }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Invoice
                                                               Number :</b>
                                                            {{ claim.repairInvoiceNumber }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Expected
                                                               Repair
                                                               Completed Date :</b>
                                                            {{ claim.expectedRepairCompletedDate }}
                                                         </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Invoice
                                                               status
                                                               :</b>
                                                            {{ claim.invoiceStatus }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Actual
                                                               Repair/Replacement Amount:</b>

                                                            {{ claim.actualReplacementAmount }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Revised
                                                               Expected
                                                               Repair Completed Date :</b>

                                                            {{ claim.revisedExpectedRepairCompletedDate }}</label>
                                                      </div>
                                                      
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_10_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_10_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Replacement</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse"
                                                id="m_accordion_1_item_10_body" role="tabpanel"
                                                aria-labelledby="m_accordion_1_item_10_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>BER :</b>
                                                         {{ claim.berStatus }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Phone Collected
                                                            :</b>
                                                         {{ claim.phoneCollected }} </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_11_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_11_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">BER Fee Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_11_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_11_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Applicable Fee
                                                            :</b>
                                                         <i class="fa fa-rupee" style="font-size:13px"></i>
                                                         {{ claim.ber }}
                                                      </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment status
                                                            :</b>
                                                         {{ claim.berStatus }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Fee paid by
                                                            customer
                                                            :</b>
                                                         {{ claim.berFeePaidByCustomer }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment date
                                                            :</b>

                                                         {{ claim.berPaymentDate | date:'dd-MMMM-yyyy' }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Transaction
                                                            reference :</b>
                                                         {{ claim.berTransactionRef }}</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_12_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_12_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Theft Claim</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_12_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_12_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Complete
                                                            Document
                                                            Received Date :</b>
                                                         {{ claim.docRecivedDate }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Customer
                                                            Notified
                                                            Date :</b>
                                                         {{ claim.customerNotifiedDate }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Theft TAT
                                                            :</b>
                                                         {{ claim.theftTat }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Photo Id
                                                            (Theft)
                                                            :</b>
                                                         {{ claim.photoId }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Sim Blocking
                                                            :</b>
                                                         {{ claim.simBlocking }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Completed claim
                                                            form
                                                            with signature (Theft) :</b>

                                                         {{ claim.isFormCompletedWithSignature }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Discharge
                                                            Voucher :</b>
                                                         {{ claim.claimDischargeVoucher }}</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_13_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_13_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Under Writer Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_13_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_13_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Underwriter
                                                            Name
                                                            :</b>
                                                         {{ claim.underwriterName }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Sub Status
                                                            :</b>
                                                         {{ claim.l1SubStatus }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Queried by
                                                            underwriter TS :</b>
                                                         {{ claim.l1QueriedByUnderwriterTS }}
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Query
                                                               Resolved
                                                               Date TS :</b> </label>
                                                         {{ claim.l1QueryResolvedDateTS }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 TAT Response
                                                            :</b>
                                                         {{ claim.l1TatResponse }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 TAT for
                                                            Query
                                                            Resolved vs Final Response :</b>

                                                         {{ claim.l1TatForQueryResolvedVsFinalResponse }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 submitted to
                                                            underwriter :</b>
                                                         {{ claim.l2SubmittedToUnderwriter }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 sub status
                                                            :</b>
                                                         {{ claim.l2SubStatus }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Queried by
                                                            Underwriter Ts :</b>
                                                         {{ claim.l2QueriedByUnderwriterTS }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Query
                                                            Resolved
                                                            Ts
                                                            :</b>
                                                         {{ claim.l2QueryResolvedTS }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Remarks by
                                                            u/w
                                                            :</b>
                                                         {{ claim.l2RemarksByUW }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Approved Amount
                                                            (Rs)
                                                            :</b>
                                                         {{ claim.approvedAmnt }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 submitted to
                                                            underwriter time :</b>

                                                         {{ claim.l2SubmittedToUnderwriterTime }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 TAT Response
                                                            :</b>
                                                         {{ claim.l2TatResponse }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>L2 TAT for
                                                            Query
                                                            Resolved vs Final Response :</b>

                                                         {{ claim.l2TatForQueryResolvedVsFinalResponse }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Write Off
                                                            Reason
                                                            :</b>
                                                         {{ claim.writeOffReason }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Drop Off
                                                            Comment
                                                            :</b>
                                                         {{ claim.dropOffComment }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>L1 Manager
                                                            Comment
                                                            :</b>
                                                         {{ claim.l1ManagerComment }} </label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>L2 Manager
                                                            Comment
                                                            :</b>
                                                         {{ claim.l2ManagerComment }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>Fraud Remark
                                                            Comment :</b>
                                                         {{ claim.fraudRemarkComment }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>L1
                                                            Investigation
                                                            :</b>
                                                         {{ claim.l1Investigation }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-12 col-lg-12 col-form-label"><b>L1 Verify
                                                            Remarks :</b>
                                                         {{ claim.l1VerifyRemarks }} </label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_15_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_15_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Comments Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_15_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_15_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <p><strong>Test Claims</strong></p>
                                                <p>Author: Siddhesh Khot on 11-10-2018 18:06:41</p>
                                                <hr>
                                                <p><strong>As this is the ICICI cases so its been WRITE OFF as per the
                                                      management approval - Pratik</strong></p>
                                                <p>Author: Sharik Qureshi on 11-10-2018 18:06:41</p>
                                                <hr>
                                             </div>
                                          </div>
                                       </div>-->
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_16_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_16_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Claim SMS History</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_16_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_16_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <p>{{ claim.claimSMSHistory }}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_17_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_17_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Claim Email History</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_17_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_17_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <p>{{ claim.claimEmailHistory }}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_18_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_18_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Tracking Information</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_18_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_18_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <p>{{ claim.tractingInformation }}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                       <!--begin::Item-->
                                       <div class="m-accordion__item">
                                          <div class="m-accordion__item-head collapsed" role="tab"
                                             id="m_accordion_1_item_19_head" data-toggle="collapse"
                                             href="#m_accordion_1_item_19_body" aria-expanded="    false">
                                             <span class="m-accordion__item-title">Change History</span>
                                             <span class="m-accordion__item-mode"></span>
                                          </div>
                                          <div class="m-accordion__item-body collapse" id="m_accordion_1_item_19_body"
                                             role="tabpanel" aria-labelledby="m_accordion_1_item_19_head"
                                             data-parent="#m_accordion_1">
                                             <div class="m-accordion__item-content">
                                                <div class="m-form__section">
                                                   <p>{{ claim.changeHistory }}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <!--end::Item-->
                                    </div>
                                    <!--end::Section-->
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane" id="m_portlet_base_demo_1_4_tab_content" role="tabpanel">
                              <div
                                 *ngIf="searchResult.activity != null && searchResult.activity.episodeChats.length > 0"
                                 class="m-accordion m-accordion--default" id="m_accordion_activities_1" role="tablist">
                                 <!-- single activity -->
                                 <div *ngFor="let episodeChat of searchResult.activity.episodeChats; let index=index;"
                                    class="m-accordion__item">
                                    <div class="m-accordion__item-head collapsed" role="tab"
                                       [id]="'m_accordion_1_activities_item_' + index + '_head'" data-toggle="collapse"
                                       [attr.href]="'#m_accordion_1_activities_item_' + index + '_body'"
                                       aria-expanded="false">
                                       <span class="m-accordion__item-title">Conversation {{ index + 1 }}</span>
                                       <span class="m-accordion__item-mode"></span>
                                    </div>
                                    <div class="m-accordion__item-body collapse"
                                       [id]="'m_accordion_1_activities_item_' + index + '_body'" role="tabpanel"
                                       [attr.aria-labelledby]="'m_accordion_1_activities_item_' + index + '_head'"
                                       data-parent="#m_accordion_activities_1">
                                       <div class="m-accordion__item-content">
                                          <div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
                                             <div class="m-messenger__messages m-scrollable">
                                                <div *ngFor="let message of episodeChat.chatMessages.messages">
                                                   <div *ngIf="message.from == 'AUTO'" class="m-messenger__wrapper">
                                                      <div class="m-messenger__message m-messenger__message--in">
                                                         <div class="m-messenger__message-pic">
                                                            <img src="./assets/images/user3.png" alt="" />
                                                         </div>
                                                         <div class="m-messenger__message-body">
                                                            <div class="m-messenger__message-arrow"></div>
                                                            <div class="m-messenger__message-content">
                                                               <div class="m-messenger__message-username">
                                                                  Auto wrote
                                                               </div>
                                                               <div class="m-messenger__message-text">
                                                                  {{ message.messageText }}
                                                               </div>
                                                            </div>
                                                            <div class="m-messenger__datetime">
                                                               {{ getStringDateFromMillis(message.messageTime.$date) }}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div *ngIf="message.from != 'AUTO'" class="m-messenger__wrapper">
                                                      <div class="m-messenger__message m-messenger__message--out">
                                                         <div class="m-messenger__message-body">
                                                            <div class="m-messenger__message-arrow"></div>
                                                            <div class="m-messenger__message-content">
                                                               <div
                                                                  *ngIf="message.displayText != null && message.displayText.trim().length != 0"
                                                                  class="m-messenger__message-text">
                                                                  {{ message.displayText }}
                                                               </div>
                                                               <div
                                                                  *ngIf="message.displayText == null || message.displayText.trim().length == 0"
                                                                  class="m-messenger__message-text">
                                                                  {{ message.messageText }}
                                                               </div>
                                                            </div>
                                                            <div class="m-messenger__datetime">12:30PM</div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="m-messenger__seperator"></div>
                                             <div class="m-messenger__form">
                                                <div class="m-messenger__form-controls">
                                                   <input type="text" readonly name="" placeholder="Type here..."
                                                      class="m-messenger__form-input">
                                                </div>
                                                <div class="m-messenger__form-tools">
                                                   <a href="" class="m-messenger__form-attachment">
                                                      <i class="la la-paperclip"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- <div class="tab-pane" id="m_portlet_base_demo_1_5_tab_content" role="tabpanel">
               <h4 class="m-form__heading-title">Checklist</h4>
               <div class="m-section__content">
                  <ul class="ul-style1">
                     <li>Policy in force</li>
                     <li>Claims exhausted</li>
                     <li>Benefits exhausted</li>
                     <li>Store Location Score</li>
                  </ul>
               </div>
               </div> -->
                           <div class="tab-pane" id="m_portlet_base_demo_1_6_tab_content" role="tabpanel">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt
                              ut
                              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                              ullamco
                              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                              in
                              voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                           </div>
                        </div>
                     </div>

                     <!--end::Portlet-->
                  </div>
                  <!--Right side-->
               </div>
               <div class="col-5 col-lg-5 col-md-5 col-sm-5 col-xl-5" style="height: 480px;">
                  <iframe style="margin-top: 0px; height: 600px;" #autoBot (click)="virtualAgentUrl"
                     [src]="virtualAgentUrl" class="iframeStyle" frameborder="0"></iframe>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- end:: Body -->
   <!-- end:: Page -->
   <!-- begin::Scroll Top -->
   <div id="m_scroll_top" class="m-scroll-top"> <i class="la la-arrow-up"></i> </div>
   <!-- end::Scroll Top -->

   <div style="position: absolute; left: 10px; bottom: 30px;">
      <eco-fab-speed-dial>
         <eco-fab-speed-dial-trigger>
            <button style="color: #3E7BC7" mat-fab (click)="doAction('menu')">
               <mat-icon>menu</mat-icon>
            </button>
         </eco-fab-speed-dial-trigger>
         <eco-fab-speed-dial-actions>
            <button mat-mini-fab (click)="newChat()">
               <mat-icon>add</mat-icon>
            </button>
            <button mat-mini-fab (click)="refreshChat()">
               <mat-icon>refresh</mat-icon>
            </button>
         </eco-fab-speed-dial-actions>
      </eco-fab-speed-dial>
   </div>