import { v4 as uuid } from 'uuid';
import { Component, OnInit } from '@angular/core';
import { VerifyOtp } from '../../models/verifyotp.model'
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import { HostListener } from '@angular/core';
import { UploadService } from "../../services/upload.service";
import { SaveServices } from "../../services/save.services";
import { DataSharingService, UniversalUser } from '../../services/shared.service'
import { CustomerSevice } from '../../services/customer.service'
import { AWSUploadedDoc, Documents } from '../../models/documents.model'
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from '../../models/profile.model';
import { Customer } from '../../models/customer.model';
import { User } from '../../models/user.model';
import { Claim } from '../../models/claim.model';
import { FileUploadData } from '../../models/fileupload.model';
import { timingSafeEqual } from 'crypto';
import { Payment, RazorPayOrder } from '../../models/payment.model';
declare var Razorpay: any;

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  customers: Customer[];
  selectedCustomer: Customer;
  selectedClaimNumber: string = null;
  selectedImei: string = null;
  selectedCreatedDt: number = 0;
  createdDt: number = 0;
  phoneNumber: string = null;
  selectedClaim: Claim;
  verifyOtp: VerifyOtp;
  awsUploadedDoc: AWSUploadedDoc;
  payModel = new Profile();
  tempDocument: Documents;
  fileUploadData: FileUploadData;
  previousDocuments: Documents[];
  nextDocumnets: Documents[];
  lastDocument: Documents;
  submitDocuments: Documents[];
  errorMessage: string = null;
  showPayment: boolean = false;
  isPickupAddressSameAsDispatchAddress: boolean = false;
  addressSubmitted: boolean = false;
  allPolicyCancelled: boolean = false;
  orderId: string = null;

  test: string;
  constructor(
    public router: Router, public route: ActivatedRoute,
    public uploadService: UploadService,
    public spinner: NgxSpinnerService,
    public saveService: SaveServices,
    private dataSharingService: DataSharingService,
    private universalUser: UniversalUser,
    private customerService: CustomerSevice
  ) {
    this.verifyOtp = new VerifyOtp();
    this.selectedCustomer = null;
    this.selectedClaim = null;
    this.customers = [];
    this.tempDocument = null;
    this.fileUploadData = null;
    this.previousDocuments = [];
    this.nextDocumnets = [];
    this.submitDocuments = [];
    this.lastDocument = null;
  }
  paymentId: string;
  paymentStatus = "";
  paymentRequestId: string;

  ngOnInit() {
    console.log(this.route.snapshot);
    console.log("*********** shared object **************");
    //console.log(this.dataSharingService.getSharedObject());

    this.paymentId = this.route.snapshot.queryParams["payment_id"];
    this.paymentStatus = this.route.snapshot.queryParams["payment_status"];
    this.paymentRequestId = this.route.snapshot.queryParams["payment_request_id"];
    this.createdDt = this.route.snapshot.params["createdDt"];
    console.log("query imei :: " + this.selectedImei);
    console.log("payment status :: " + this.paymentStatus);
    if (this.paymentStatus) {
      console.log("user");
      console.log(this.universalUser.getUser());
      var phoneNumber: string = null;
      if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
        phoneNumber = this.universalUser.getUser().phoneNumber;
      }
      else {
        phoneNumber = this.phoneNumber;
      }
      if (this.paymentStatus == "Failed") {
        //this.errorMessage = "Paymnet transaction failed. Please try again later"
        this.getCustomerByCreatedDt(true);
        //this.getCustomersByPhoneNumber(phoneNumber);
      }
      if (this.paymentStatus == "Credit") {
        this.getCustomerByCreatedDt(true);
        //this.getCustomersByPhoneNumber(phoneNumber, true);
      }
    }
    else {
      if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
        this.getCustomersByPhoneNumber(this.universalUser.getUser().phoneNumber);
      }
    }



    //   if (this.paymentStatus != null && this.paymentStatus =='Credit') {
    //    console.log(this.payment);
    //    this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
    //    this.savepayment();
    //   }
    //   else{
    //   console.log('--else-init---')
    //   console.log(this.verifyOtp);
    //   //this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
    //   console.log('2 init---')
    //   console.log(this.verifyOtp);
    //   if (this.verifyOtp==null){
    //     //this.router.navigate(['/pg/login']);
    //   }
    //   else{
    //     for (let pay of this.verifyOtp.customer.payments)
    //     {
    //       if (pay.paymentStatus == 'Credit'){
    //         this.paymentStatus='Credit';
    //       }
    //     }
    //     //console.log(this.verifyOtp.claim.applicableFee);
    //   }
    // }
  }

  ngOnDestroy(): void {
    //sessionStorage.clear();
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    //window.sessionStorage.clear();
  }

  selectedFiles: FileList;

  getCustomerByCreatedDt(updatedPayment?: boolean) {
    this.spinner.show();
    this.customerService.getCustomerByCreatedDt(this.createdDt)
      .subscribe(
        response => {
          console.log("getCustomerByCreatedDt");
          if (response && response.success && response.result && response.result) {
            console.log("getCustomerByCreatedDt");
            console.log(response.result);
          
            this.customers = [response.result];
            this.setCustomer(updatedPayment);
          }
          else {
            this.errorMessage = "Something went wrong"
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      )
  }

  getCustomersByPhoneNumber(phoneNumber: string, updatePaymnet?: boolean) {
    this.spinner.show();
    this.customerService.getCustomersByPhoneNumber(phoneNumber)
      .subscribe(
        response => {
          console.log("getCustomersByPhoneNumber")
          console.log(response.result);
          if (response && response.success && response.result && response.result) {
            if (!this.customers) {
              this.customers = [];
            }
            for (let cust of response.result) {
              console.log(cust);
              this.customers.push(cust);
            }
            console.log("customers");
            console.log(this.customers);
            this.setCustomer(updatePaymnet);
            this.isAllPolicyCancelled();
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
          }
        },
        error => {
          console.log("getCustomersByPhoneNumber error");
          console.log(error);
          this.errorMessage = "Something went wrong. Please try again latter";
          this.spinner.hide();
        }
      )
  }

  setCustomer(updatePayment?: boolean) {
    if (this.customers && this.customers.length == 1) {
      if (!this.isValidCustomer(this.customers[0])) {
        if (this.customers[0].isPolicyCancelled) {
          this.errorMessage = "Your policy has been cancelled"
        }
        else if (this.customers[0].isPaymentDone) {
          this.errorMessage = "Your policy payment has been done"
        }

      }
      else {
        this.selectedCustomer = this.customers[0];
        this.selectedImei = this.selectedCustomer.imei_sno_1;
      }

    }
    else if (this.customers && this.selectedImei) {
      console.log("selected imei");
      console.log(this.selectedImei);
      for (let cust of this.customers) {
        if (cust.imei_sno_1 == this.selectedImei) {
          // const index = this.selectedCustomer.claimNumber.indexOf("t");
          // if (index != -1) {
          //   this.selectedCustomer.claimNumber = this.selectedCustomer.claimNumber.substring(0, index);
          // }

          this.selectedCustomer = cust;

        }
      }
    }
    else if (this.customers && this.customers.length > 1) {
      var customers = []
      for(let cust of this.customers) {
        if (this.isValidCustomer(cust)) {
          customers.push(cust);
        }
      }
      if (customers.length == 1) {
        this.selectedCustomer = customers[0];
        this.selectedImei = customers[0].imei_sno_1;
      }
    }
    if (updatePayment) {
      console.log("*****************************&&&&&&&&&&&&&&&");
      this.updateCustomerWithPayment();  
    }
  }

  updateCustomerWithPayment() {
    if (this.paymentStatus == "Credit") {
      console.log("payment successfull");
      console.log(this.selectedCustomer);
      this.selectedCustomer.isPaymentDone = true;
      this.selectedCustomer.paymentStatus = "PAID"
      this.selectedCustomer.paymentTransactionRef = this.paymentId;
      
      }
      else if (this.paymentStatus == "Failed"){
        this.selectedCustomer.paymentStatus = "FAILED"
      }
      this.updateCustomer();
      
  }

  updateCustomer() {
    this.spinner.show();
    this.customerService.updateCustomer(this.selectedCustomer)
      .subscribe(
        response => {
          console.log("update customer");
          console.log(response);
          this.spinner.hide();
          this.updateFlow();
        },
        error => {
          console.log("updatecustomer error");
          this.errorMessage = "Something went wrong, Please try again later."
        }
      )
  }

  updateFlow() {
    this.spinner.show();
    this.customerService.updateFlow(this.selectedCustomer)
      .subscribe(
        response => {
          console.log("updateFlow");
          console.log(response);
          
          if (response.success) {
            this.router.navigate(['/pg/success']);
            //alert("success")
          }
          else {
            this.errorMessage = "Something went wrong"
            this.spinner.hide();
          }
        },
        error => {
          this.errorMessage = "Somrthing went wrong. Please try again latter";
          this.spinner.hide();
        }
        
      )
  }



  paynow(fee) {
    this.spinner.show();
    if (fee < 9) {
      fee = 10
    }

    this.payModel.amount = fee;
    this.payModel.phone = this.selectedCustomer.customer_mobile
    this.payModel.email = this.selectedCustomer.customer_email;
    this.payModel.redirectUrl = environment.RETURN_URL + "/" + this.selectedCustomer.createdDt
    this.payModel.buyerName = this.selectedCustomer.customer_fname
    var purpose = ""
    if (this.selectedCustomer.imei_sno_1) {
      purpose = this.selectedCustomer.imei_sno_1;
    }
    if (!purpose) {
      purpose = this.selectedCustomer.customer_mobile;
    }
    if (this.selectedImei) {
      purpose = purpose + "-" + this.selectedImei;
    }
    this.payModel.purpose = purpose;
    console.log('Applicable fees to be paid');
    console.log(fee);
    this.saveService.payNow(this.payModel)
      .subscribe(
        response => {
          console.log("resturn response");
          //console.log(response.result.payment_request.longurl);
          if (response.success && response.result && response.result.payment_request && response.result.payment_request.longurl) {
            window.location.href = response.result.payment_request.longurl + "";
          }
          else {
            this.spinner.hide();
            if (response && response.error && response.error.message) {
              this.errorMessage = response.error.message;
            }
            else{
              this.errorMessage = "Something went wrong"
            }
          }
          //window.location.href = response+"";
          // window.open(response+"", "_blank");
          // setTimeout(() => {
          //   this.spinner.hide();
          // }, 3000);
        },
        error => {
          console.log("error");
        }
      )


  }
  // savepayment(){
  //   this.spinner.show();
  //   for(let pay of this.verifyOtp.customer.payments)
  //   {
  //     pay.paymentStatus=this.paymentStatus;
  //     pay.paymentRequestId=this.paymentRequestId;
  //     pay.paidAmount=this.verifyOtp.claim.applicableFee;
  //     pay.paymentId=this.payment;
  //   }
  //   this.saveService.saveDocument(this.verifyOtp.customer);
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 3000);
  //   console.log('Timed out..!!')
  // }

  submitIMEI() {
    console.log("selected selectedCreatedDt");
    console.log(this.selectedCreatedDt);
    if (this.customers && this.selectedCreatedDt) {
      for (let cust of this.customers) {
        if (this.selectedCreatedDt != 0 && cust.createdDt && cust.createdDt == this.selectedCreatedDt) {
          this.selectedImei = cust.imei_sno_1;
          this.selectedCustomer = cust;
          console.log("selected customer");
          console.log(this.selectedCustomer);
          break;
        }
      }
    }
  }

  isAddressProvided() {
    return this.addressSubmitted && this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
      && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
      && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
  }

  isClaimAddressPresent() {
    return this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
      && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
      && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
  }


  replaceDocument() {
    if (this.tempDocument && this.submitDocuments) {
      const index = this.submitDocuments.indexOf(this.tempDocument);
      if (index != -1) {
        this.previousDocuments.push(this.tempDocument);
        this.submitDocuments.splice(index, 1);
      }
    }
  }

  onSelectPickupSameAsDispatch(value) {
    console.log("onSelectPickupSameAsDispatch");
    console.log(value);
    console.log(this.isPickupAddressSameAsDispatchAddress);
    if (this.isPickupAddressSameAsDispatchAddress) {
      this.selectedClaim.pickupArea = this.selectedClaim.dispatchArea;
      this.selectedClaim.pickupUnit = this.selectedClaim.dispatchUnit;
      this.selectedClaim.pickupCity = this.selectedClaim.dispatchCity;
      this.selectedClaim.country = this.selectedClaim.dispatchCountry;
      this.selectedClaim.pickupState = this.selectedClaim.dispatchState;
    }
  }

  getPendingPaymentCustomers() {
    var customers = [];
    for (let cust of this.customers) {
      if (this.isValidCustomer(cust)) {
        customers.push(cust);
      }
    }
    if (customers.length == 1) {
      this.setCustomer();
    }
    return customers;
  }

  isValidCustomer(customer: Customer) {
    return !customer.isPaymentDone && !customer.isPolicyCancelled
  }

  isAllPolicyCancelled() {
    if (this.customers) {
      for (let cust of this.customers) {
        if (!cust.isPolicyCancelled) {
          this.allPolicyCancelled = false;
          return;
        }
      }
      this.allPolicyCancelled = true;
    }
    
  }

  isAllPoliciesPaymentDone() {
    if (this.customers) {
      for(let pol of this.customers) {
        if(!pol.isPaymentDone) {
          return false;
        }
      }
      return true;
    }
    return false;
    
  }

  isAllPoliciesCancelled() {
    if(!this.isAllPoliciesPaymentDone()) {
      if (this.customers) {
        for(let pol of this.customers) {
          if (pol.isPaymentDone) {
            continue;
          }
          if (!pol.isPolicyCancelled) {
            return false;
          }
        }
        return true;
      }
    }
    return false
  }

  razorPaymentOrder(fee, doNothing?: boolean) {
    if (doNothing) {
      return;
    }
    this.spinner.show();
    const razorPayOrder = new RazorPayOrder();
    razorPayOrder.amount = fee * 100;
    razorPayOrder.currency = "INR";
    razorPayOrder.payment_capture = "0";
    razorPayOrder.receipt = this.selectedCustomer.imei_sno_1;
    razorPayOrder.notes = {
      "imei" : this.selectedCustomer.imei_sno_1,
      "phoneNumber" : this.selectedCustomer.customer_mobile
    };
    
    this.saveService.razorPayOrder(razorPayOrder)
      .subscribe(
        response => {
          this.spinner.hide();
          if (response && response.success) {
            this.doRazorPayPayment(response.result);
          }
          else if (response && response.error && response.error.message){
            this.errorMessage = response.error.message;
          }
          else {
            this.errorMessage = "Something went wrong. Please try again later";
          }
        },
        error => {
          this.spinner.hide();
        }
      )
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  handler(response) {
    console.log("***************");
    console.log(response);
    this.spinner.show();
    this.verifySignature(response);
  }


  doRazorPayPayment(orderResponse: any) {
    var options = {
      "key" : orderResponse.payment_api_key,
      "amount": orderResponse.amount,
      "name": "Amtrust",
      "order_id" : orderResponse.id,
      "description": "Excess fee payment",
      "notes" : {
        "imei" : this.selectedCustomer.imei_sno_1,
        "phoneNumber" : this.selectedCustomer.customer_mobile,
        "email" : this.selectedCustomer.customer_email
      },
      "handler" : this.handler.bind(this),
      // "handler": (function (response) {
      //   console.log(response);
      //   this.spinner.show();
        
      //   // //alert(response.razorpay_payment_id);
      //   // this.showPaymentSuccessModal();
        
      //   //this.showModal = true;
      //   //this.spinner.hide();
      //   console.log(this.showModal);
      //   this.verifySignature(response);
        
        
      // }).bind(this),
      "prefill": {
        "name": this.selectedCustomer.customer_fname,
        "email": this.selectedCustomer.customer_email,
        "contact": this.selectedCustomer.customer_mobile,

      },
      "theme": {
        "color": "blue"
      }
    };
    var instance = new Razorpay(options);
    //instance.createPayment(options);
    // instance.on('payment.success', function(resp) {
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)});
    instance.open();
    
    
    // instance.on('payment.success', function(resp) {
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)}
    // );
  }

  

  verifySignature(checkoutResponse: any) {
    //this.errorMessage = "something went wrong";
    setTimeout(() => {
      this.spinner.show();
    }, 100);
    this.saveService.razorPayVerifySignture(checkoutResponse)
      .subscribe(
        response => {
          this.spinner.hide();
          if (!response || !response.success) {
            if (response.error && response.error.message) {
              this.errorMessage = response.error.message;
            }
            else {
              this.errorMessage = "Something went wrong. Please try again later";
            }
            
          }
          else {
            if (response.success) {
              this.paymentStatus = "Credit";
              this.paymentId = checkoutResponse.razorpay_payment_id;
              this.orderId = checkoutResponse.razorpay_order_id;
              //this.spinner.show();
              //alert("Payment is successfull");
            
              //this.showPaymentSuccessModal();
              //$("#paymentsuccessmodal").modal('show');
              //this.spinner.show();
              this.updateCustomerWithPayment();
              //alert("Wait till payment is done");

            }
            else {
              this.errorMessage = "Something went wrong. Please try again later";
            }
          }
          
        },
        error => {
          this.errorMessage = "Something went wrong. Please try again later";
          this.spinner.hide();
        }
      )
  }
  

}
