import { OnInit } from '@angular/core';
import { VerifyOtp } from '../../models/verifyotp.model';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { UploadService } from "../../services/upload.service";
import { SaveServices } from "../../services/save.services";
import { DataSharingService, UniversalUser } from '../../services/shared.service';
import { CustomerSevice } from '../../services/customer.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from '../../models/profile.model';
import { RazorPayOrder } from '../../models/payment.model';
var CustomerComponent = /** @class */ (function () {
    function CustomerComponent(router, route, uploadService, spinner, saveService, dataSharingService, universalUser, customerService) {
        this.router = router;
        this.route = route;
        this.uploadService = uploadService;
        this.spinner = spinner;
        this.saveService = saveService;
        this.dataSharingService = dataSharingService;
        this.universalUser = universalUser;
        this.customerService = customerService;
        this.selectedClaimNumber = null;
        this.selectedImei = null;
        this.selectedCreatedDt = 0;
        this.createdDt = 0;
        this.phoneNumber = null;
        this.payModel = new Profile();
        this.errorMessage = null;
        this.showPayment = false;
        this.isPickupAddressSameAsDispatchAddress = false;
        this.addressSubmitted = false;
        this.allPolicyCancelled = false;
        this.orderId = null;
        this.paymentStatus = "";
        this.verifyOtp = new VerifyOtp();
        this.selectedCustomer = null;
        this.selectedClaim = null;
        this.customers = [];
        this.tempDocument = null;
        this.fileUploadData = null;
        this.previousDocuments = [];
        this.nextDocumnets = [];
        this.submitDocuments = [];
        this.lastDocument = null;
    }
    CustomerComponent.prototype.ngOnInit = function () {
        console.log(this.route.snapshot);
        console.log("*********** shared object **************");
        //console.log(this.dataSharingService.getSharedObject());
        this.paymentId = this.route.snapshot.queryParams["payment_id"];
        this.paymentStatus = this.route.snapshot.queryParams["payment_status"];
        this.paymentRequestId = this.route.snapshot.queryParams["payment_request_id"];
        this.createdDt = this.route.snapshot.params["createdDt"];
        console.log("query imei :: " + this.selectedImei);
        console.log("payment status :: " + this.paymentStatus);
        if (this.paymentStatus) {
            console.log("user");
            console.log(this.universalUser.getUser());
            var phoneNumber = null;
            if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
                phoneNumber = this.universalUser.getUser().phoneNumber;
            }
            else {
                phoneNumber = this.phoneNumber;
            }
            if (this.paymentStatus == "Failed") {
                //this.errorMessage = "Paymnet transaction failed. Please try again later"
                this.getCustomerByCreatedDt(true);
                //this.getCustomersByPhoneNumber(phoneNumber);
            }
            if (this.paymentStatus == "Credit") {
                this.getCustomerByCreatedDt(true);
                //this.getCustomersByPhoneNumber(phoneNumber, true);
            }
        }
        else {
            if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
                this.getCustomersByPhoneNumber(this.universalUser.getUser().phoneNumber);
            }
        }
        //   if (this.paymentStatus != null && this.paymentStatus =='Credit') {
        //    console.log(this.payment);
        //    this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
        //    this.savepayment();
        //   }
        //   else{
        //   console.log('--else-init---')
        //   console.log(this.verifyOtp);
        //   //this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
        //   console.log('2 init---')
        //   console.log(this.verifyOtp);
        //   if (this.verifyOtp==null){
        //     //this.router.navigate(['/pg/login']);
        //   }
        //   else{
        //     for (let pay of this.verifyOtp.customer.payments)
        //     {
        //       if (pay.paymentStatus == 'Credit'){
        //         this.paymentStatus='Credit';
        //       }
        //     }
        //     //console.log(this.verifyOtp.claim.applicableFee);
        //   }
        // }
    };
    CustomerComponent.prototype.ngOnDestroy = function () {
        //sessionStorage.clear();
    };
    CustomerComponent.prototype.unloadHandler = function (event) {
        //window.sessionStorage.clear();
    };
    CustomerComponent.prototype.getCustomerByCreatedDt = function (updatedPayment) {
        var _this = this;
        this.spinner.show();
        this.customerService.getCustomerByCreatedDt(this.createdDt)
            .subscribe(function (response) {
            console.log("getCustomerByCreatedDt");
            if (response && response.success && response.result && response.result) {
                console.log("getCustomerByCreatedDt");
                console.log(response.result);
                _this.customers = [response.result];
                _this.setCustomer(updatedPayment);
            }
            else {
                _this.errorMessage = "Something went wrong";
            }
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.getCustomersByPhoneNumber = function (phoneNumber, updatePaymnet) {
        var _this = this;
        this.spinner.show();
        this.customerService.getCustomersByPhoneNumber(phoneNumber)
            .subscribe(function (response) {
            console.log("getCustomersByPhoneNumber");
            console.log(response.result);
            if (response && response.success && response.result && response.result) {
                if (!_this.customers) {
                    _this.customers = [];
                }
                for (var _i = 0, _a = response.result; _i < _a.length; _i++) {
                    var cust = _a[_i];
                    console.log(cust);
                    _this.customers.push(cust);
                }
                console.log("customers");
                console.log(_this.customers);
                _this.setCustomer(updatePaymnet);
                _this.isAllPolicyCancelled();
                _this.spinner.hide();
            }
            else {
                _this.spinner.hide();
            }
        }, function (error) {
            console.log("getCustomersByPhoneNumber error");
            console.log(error);
            _this.errorMessage = "Something went wrong. Please try again latter";
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.setCustomer = function (updatePayment) {
        if (this.customers && this.customers.length == 1) {
            if (!this.isValidCustomer(this.customers[0])) {
                if (this.customers[0].isPolicyCancelled) {
                    this.errorMessage = "Your policy has been cancelled";
                }
                else if (this.customers[0].isPaymentDone) {
                    this.errorMessage = "Your policy payment has been done";
                }
            }
            else {
                this.selectedCustomer = this.customers[0];
                this.selectedImei = this.selectedCustomer.imei_sno_1;
            }
        }
        else if (this.customers && this.selectedImei) {
            console.log("selected imei");
            console.log(this.selectedImei);
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var cust = _a[_i];
                if (cust.imei_sno_1 == this.selectedImei) {
                    // const index = this.selectedCustomer.claimNumber.indexOf("t");
                    // if (index != -1) {
                    //   this.selectedCustomer.claimNumber = this.selectedCustomer.claimNumber.substring(0, index);
                    // }
                    this.selectedCustomer = cust;
                }
            }
        }
        else if (this.customers && this.customers.length > 1) {
            var customers = [];
            for (var _b = 0, _c = this.customers; _b < _c.length; _b++) {
                var cust = _c[_b];
                if (this.isValidCustomer(cust)) {
                    customers.push(cust);
                }
            }
            if (customers.length == 1) {
                this.selectedCustomer = customers[0];
                this.selectedImei = customers[0].imei_sno_1;
            }
        }
        if (updatePayment) {
            console.log("*****************************&&&&&&&&&&&&&&&");
            this.updateCustomerWithPayment();
        }
    };
    CustomerComponent.prototype.updateCustomerWithPayment = function () {
        if (this.paymentStatus == "Credit") {
            console.log("payment successfull");
            console.log(this.selectedCustomer);
            this.selectedCustomer.isPaymentDone = true;
            this.selectedCustomer.paymentStatus = "PAID";
            this.selectedCustomer.paymentTransactionRef = this.paymentId;
        }
        else if (this.paymentStatus == "Failed") {
            this.selectedCustomer.paymentStatus = "FAILED";
        }
        this.updateCustomer();
    };
    CustomerComponent.prototype.updateCustomer = function () {
        var _this = this;
        this.spinner.show();
        this.customerService.updateCustomer(this.selectedCustomer)
            .subscribe(function (response) {
            console.log("update customer");
            console.log(response);
            _this.spinner.hide();
            _this.updateFlow();
        }, function (error) {
            console.log("updatecustomer error");
            _this.errorMessage = "Something went wrong, Please try again later.";
        });
    };
    CustomerComponent.prototype.updateFlow = function () {
        var _this = this;
        this.spinner.show();
        this.customerService.updateFlow(this.selectedCustomer)
            .subscribe(function (response) {
            console.log("updateFlow");
            console.log(response);
            if (response.success) {
                _this.router.navigate(['/pg/success']);
                //alert("success")
            }
            else {
                _this.errorMessage = "Something went wrong";
                _this.spinner.hide();
            }
        }, function (error) {
            _this.errorMessage = "Somrthing went wrong. Please try again latter";
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.paynow = function (fee) {
        var _this = this;
        this.spinner.show();
        if (fee < 9) {
            fee = 10;
        }
        this.payModel.amount = fee;
        this.payModel.phone = this.selectedCustomer.customer_mobile;
        this.payModel.email = this.selectedCustomer.customer_email;
        this.payModel.redirectUrl = environment.RETURN_URL + "/" + this.selectedCustomer.createdDt;
        this.payModel.buyerName = this.selectedCustomer.customer_fname;
        var purpose = "";
        if (this.selectedCustomer.imei_sno_1) {
            purpose = this.selectedCustomer.imei_sno_1;
        }
        if (!purpose) {
            purpose = this.selectedCustomer.customer_mobile;
        }
        if (this.selectedImei) {
            purpose = purpose + "-" + this.selectedImei;
        }
        this.payModel.purpose = purpose;
        console.log('Applicable fees to be paid');
        console.log(fee);
        this.saveService.payNow(this.payModel)
            .subscribe(function (response) {
            console.log("resturn response");
            //console.log(response.result.payment_request.longurl);
            if (response.success && response.result && response.result.payment_request && response.result.payment_request.longurl) {
                window.location.href = response.result.payment_request.longurl + "";
            }
            else {
                _this.spinner.hide();
                if (response && response.error && response.error.message) {
                    _this.errorMessage = response.error.message;
                }
                else {
                    _this.errorMessage = "Something went wrong";
                }
            }
            //window.location.href = response+"";
            // window.open(response+"", "_blank");
            // setTimeout(() => {
            //   this.spinner.hide();
            // }, 3000);
        }, function (error) {
            console.log("error");
        });
    };
    // savepayment(){
    //   this.spinner.show();
    //   for(let pay of this.verifyOtp.customer.payments)
    //   {
    //     pay.paymentStatus=this.paymentStatus;
    //     pay.paymentRequestId=this.paymentRequestId;
    //     pay.paidAmount=this.verifyOtp.claim.applicableFee;
    //     pay.paymentId=this.payment;
    //   }
    //   this.saveService.saveDocument(this.verifyOtp.customer);
    //   setTimeout(() => {
    //     this.spinner.hide();
    //   }, 3000);
    //   console.log('Timed out..!!')
    // }
    CustomerComponent.prototype.submitIMEI = function () {
        console.log("selected selectedCreatedDt");
        console.log(this.selectedCreatedDt);
        if (this.customers && this.selectedCreatedDt) {
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var cust = _a[_i];
                if (this.selectedCreatedDt != 0 && cust.createdDt && cust.createdDt == this.selectedCreatedDt) {
                    this.selectedImei = cust.imei_sno_1;
                    this.selectedCustomer = cust;
                    console.log("selected customer");
                    console.log(this.selectedCustomer);
                    break;
                }
            }
        }
    };
    CustomerComponent.prototype.isAddressProvided = function () {
        return this.addressSubmitted && this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
            && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
            && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
    };
    CustomerComponent.prototype.isClaimAddressPresent = function () {
        return this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
            && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
            && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
    };
    CustomerComponent.prototype.replaceDocument = function () {
        if (this.tempDocument && this.submitDocuments) {
            var index = this.submitDocuments.indexOf(this.tempDocument);
            if (index != -1) {
                this.previousDocuments.push(this.tempDocument);
                this.submitDocuments.splice(index, 1);
            }
        }
    };
    CustomerComponent.prototype.onSelectPickupSameAsDispatch = function (value) {
        console.log("onSelectPickupSameAsDispatch");
        console.log(value);
        console.log(this.isPickupAddressSameAsDispatchAddress);
        if (this.isPickupAddressSameAsDispatchAddress) {
            this.selectedClaim.pickupArea = this.selectedClaim.dispatchArea;
            this.selectedClaim.pickupUnit = this.selectedClaim.dispatchUnit;
            this.selectedClaim.pickupCity = this.selectedClaim.dispatchCity;
            this.selectedClaim.country = this.selectedClaim.dispatchCountry;
            this.selectedClaim.pickupState = this.selectedClaim.dispatchState;
        }
    };
    CustomerComponent.prototype.getPendingPaymentCustomers = function () {
        var customers = [];
        for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
            var cust = _a[_i];
            if (this.isValidCustomer(cust)) {
                customers.push(cust);
            }
        }
        if (customers.length == 1) {
            this.setCustomer();
        }
        return customers;
    };
    CustomerComponent.prototype.isValidCustomer = function (customer) {
        return !customer.isPaymentDone && !customer.isPolicyCancelled;
    };
    CustomerComponent.prototype.isAllPolicyCancelled = function () {
        if (this.customers) {
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var cust = _a[_i];
                if (!cust.isPolicyCancelled) {
                    this.allPolicyCancelled = false;
                    return;
                }
            }
            this.allPolicyCancelled = true;
        }
    };
    CustomerComponent.prototype.isAllPoliciesPaymentDone = function () {
        if (this.customers) {
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var pol = _a[_i];
                if (!pol.isPaymentDone) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };
    CustomerComponent.prototype.isAllPoliciesCancelled = function () {
        if (!this.isAllPoliciesPaymentDone()) {
            if (this.customers) {
                for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                    var pol = _a[_i];
                    if (pol.isPaymentDone) {
                        continue;
                    }
                    if (!pol.isPolicyCancelled) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    };
    CustomerComponent.prototype.razorPaymentOrder = function (fee, doNothing) {
        var _this = this;
        if (doNothing) {
            return;
        }
        this.spinner.show();
        var razorPayOrder = new RazorPayOrder();
        razorPayOrder.amount = fee * 100;
        razorPayOrder.currency = "INR";
        razorPayOrder.payment_capture = "0";
        razorPayOrder.receipt = this.selectedCustomer.imei_sno_1;
        razorPayOrder.notes = {
            "imei": this.selectedCustomer.imei_sno_1,
            "phoneNumber": this.selectedCustomer.customer_mobile
        };
        this.saveService.razorPayOrder(razorPayOrder)
            .subscribe(function (response) {
            _this.spinner.hide();
            if (response && response.success) {
                _this.doRazorPayPayment(response.result);
            }
            else if (response && response.error && response.error.message) {
                _this.errorMessage = response.error.message;
            }
            else {
                _this.errorMessage = "Something went wrong. Please try again later";
            }
        }, function (error) {
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.delay = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    CustomerComponent.prototype.handler = function (response) {
        console.log("***************");
        console.log(response);
        this.spinner.show();
        this.verifySignature(response);
    };
    CustomerComponent.prototype.doRazorPayPayment = function (orderResponse) {
        var options = {
            "key": orderResponse.payment_api_key,
            "amount": orderResponse.amount,
            "name": "Amtrust",
            "order_id": orderResponse.id,
            "description": "Excess fee payment",
            "notes": {
                "imei": this.selectedCustomer.imei_sno_1,
                "phoneNumber": this.selectedCustomer.customer_mobile,
                "email": this.selectedCustomer.customer_email
            },
            "handler": this.handler.bind(this),
            // "handler": (function (response) {
            //   console.log(response);
            //   this.spinner.show();
            //   // //alert(response.razorpay_payment_id);
            //   // this.showPaymentSuccessModal();
            //   //this.showModal = true;
            //   //this.spinner.hide();
            //   console.log(this.showModal);
            //   this.verifySignature(response);
            // }).bind(this),
            "prefill": {
                "name": this.selectedCustomer.customer_fname,
                "email": this.selectedCustomer.customer_email,
                "contact": this.selectedCustomer.customer_mobile,
            },
            "theme": {
                "color": "blue"
            }
        };
        var instance = new Razorpay(options);
        //instance.createPayment(options);
        // instance.on('payment.success', function(resp) {
        //   alert(resp.razorpay_payment_id),
        //   alert(resp.razorpay_order_id),
        //   alert(resp.razorpay_signature)});
        instance.open();
        // instance.on('payment.success', function(resp) {
        //   alert(resp.razorpay_payment_id),
        //   alert(resp.razorpay_order_id),
        //   alert(resp.razorpay_signature)}
        // );
    };
    CustomerComponent.prototype.verifySignature = function (checkoutResponse) {
        var _this = this;
        //this.errorMessage = "something went wrong";
        setTimeout(function () {
            _this.spinner.show();
        }, 100);
        this.saveService.razorPayVerifySignture(checkoutResponse)
            .subscribe(function (response) {
            _this.spinner.hide();
            if (!response || !response.success) {
                if (response.error && response.error.message) {
                    _this.errorMessage = response.error.message;
                }
                else {
                    _this.errorMessage = "Something went wrong. Please try again later";
                }
            }
            else {
                if (response.success) {
                    _this.paymentStatus = "Credit";
                    _this.paymentId = checkoutResponse.razorpay_payment_id;
                    _this.orderId = checkoutResponse.razorpay_order_id;
                    //this.spinner.show();
                    //alert("Payment is successfull");
                    //this.showPaymentSuccessModal();
                    //$("#paymentsuccessmodal").modal('show');
                    //this.spinner.show();
                    _this.updateCustomerWithPayment();
                    //alert("Wait till payment is done");
                }
                else {
                    _this.errorMessage = "Something went wrong. Please try again later";
                }
            }
        }, function (error) {
            _this.errorMessage = "Something went wrong. Please try again later";
            _this.spinner.hide();
        });
    };
    return CustomerComponent;
}());
export { CustomerComponent };
