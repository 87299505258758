import { Claim } from "./claim.model";
import { Customer } from "./customer.model";
var VerifyOtp = /** @class */ (function () {
    function VerifyOtp() {
        this.flag = false;
        this.phoneNumber = null;
        this.otp = null;
        this.claim = new Claim();
        this.entityId = null;
        this.customer = new Customer();
    }
    return VerifyOtp;
}());
export { VerifyOtp };
