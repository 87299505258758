import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var ConversationService = /** @class */ (function () {
    function ConversationService(httpClient) {
        this.httpClient = httpClient;
    }
    ConversationService.prototype.getChatMessages = function (episodeId) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.getMessages);
        var body = {
            episodeId: episodeId
        };
        this.httpClient.post(url, body, {
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return ConversationService;
}());
export { ConversationService };
