
export class SendOtp {
    flag: Boolean;
    phoneNumber: string;
    noData: string;

    constructor() {
        this.flag = false;
        this.phoneNumber = null;
        this.noData=null;
    }
}