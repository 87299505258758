import { CustomerInfo } from './customerInfo.model';
import { Activity } from './activity.model';
var SearchResponse = /** @class */ (function () {
    function SearchResponse() {
        this.customerInfo = new CustomerInfo();
        this.policies = [];
        this.claims = [];
        this.activity = new Activity();
        this.notes = null;
    }
    return SearchResponse;
}());
export { SearchResponse };
