import { CognitoUtil } from "./cognito.service";
var UserParametersService = /** @class */ (function () {
    function UserParametersService(cognitoUtil) {
        this.cognitoUtil = cognitoUtil;
    }
    UserParametersService.prototype.getParameters = function (callbackFunction) {
        var cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log("UserParametersService: Couldn't retrieve the user");
                }
                else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log("UserParametersService: in getParameters: " + err);
                            callbackFunction(null);
                        }
                        else {
                            callbackFunction(result);
                        }
                    });
                }
            });
        }
        else {
            callbackFunction(null);
        }
    };
    return UserParametersService;
}());
export { UserParametersService };
