export class RegistrationUser {
    // given_name: string;
    // family_name: string;
    // phone_number: string;
    email: string;
    password: string;
}

export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}