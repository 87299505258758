import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';

import { routing } from './pages.routing';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
//import { ConfirmRegistrationComponent } from './auth/confirm/confirmRegistration.component';
//import { ForgotPasswordStep1Component, ForgotPasswordStep2Component } from './auth/forgotPassword/forgotPassword.component';
import { NewPasswordComponent } from './auth/newPassword/newPassword.component';
//import { RegisterComponent } from './auth/register/register.component';
//import { ResendCodeComponent } from './auth/resendCode/resendCode.component';
import { OrderByPipe } from './home/order-by-pipe';
//import { ProfileComponent } from './profile/profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CounterModule } from 'ngx-counter';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { MatButtonModule, MatIconModule, MatStepperModule } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';
import { CustomerComponent } from './customer/customer.component';
import { SuccessComponent } from './success/success.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routing,
    NgxSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    EcoFabSpeedDialModule,
    ArchwizardModule,
    CounterModule.forRoot(),
  ],
  declarations: [
    PagesComponent,
    HomeComponent,
    LoginComponent,
    OrderByPipe,
    //ConfirmRegistrationComponent,
    //ForgotPasswordStep1Component,
    //ForgotPasswordStep2Component,
    NewPasswordComponent,
    CustomerComponent,
    SuccessComponent
    //RegisterComponent,
    //ResendCodeComponent,
    //ProfileComponent
  ]
})

export class PagesModule { }
