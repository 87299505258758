import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SuccessResponse } from '../models/success.model'
import {SendOtp} from '../models/sendotp.model'
import {VerifyOtp} from '../models/verifyotp.model'

@Injectable()
export class LoginServices {

    FUNCTION_INSTATNCE_NAME = "AMTRUST_SALES_CUSTOMER";

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json','Access-Control-Allow-Origin':'*'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    sendOtp(phoneNumber: string): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.sendotp}`;
        const body = {
            operation : "SMS_TO_CLAIM_CUSTOMER_OTP",
            phoneNumber: phoneNumber,
            functionInstanceName: this.FUNCTION_INSTATNCE_NAME,
            template: "%(otp)s is your OTP to login and make the payment for your TechGuard plan. Your OTP is valid for 30 mins, please do not share your OTP for security reasons.",
            senderName: "TECGRD"
        
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    checkMobile(mobile: string): Observable<SendOtp> {
        const subject = new Subject<SendOtp>();
        var url = `${environment.apiurl + environment.checkMobileNo}`;
       var body ={ "phoneNumber":mobile, functionInstanceName: this.FUNCTION_INSTATNCE_NAME }
        this.httpClient.post<SendOtp>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<SendOtp>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
    
    verifyCustomerOtp(mobile: string, otp: string, getCustomer?: boolean): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.verifyOtp}`;
        const body = {
            phoneNumber: mobile,
            otp: otp
        }
        if (getCustomer) {
            body["GET_CUSTOMER"] = true;
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<VerifyOtp>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    verifyOtp(mobile: string,otp : string): Observable<VerifyOtp> {
        const subject = new Subject<VerifyOtp>();
        var url = `${environment.apiurl + environment.verifyOtp}`;
        var body ={"phoneNumber":mobile,"otp":otp}
        this.httpClient.post<VerifyOtp>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<VerifyOtp>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}