import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../../services/aws/user-registration.service";
import { UserLoginService } from "../../../services/aws/user-login.service";
import { NewPasswordUser } from "../../../models/aws.model";
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
var NewPasswordComponent = /** @class */ (function () {
    function NewPasswordComponent(userRegistration, userService, router) {
        this.userRegistration = userRegistration;
        this.userService = userService;
        this.router = router;
        this.registrationUser = new NewPasswordUser();
        this.errorMessage = null;
    }
    NewPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated(function (isValidSession) {
            if (isValidSession) {
                _this.router.navigate(['/pg/newPassword']);
            }
        });
    };
    NewPasswordComponent.prototype.onRegister = function () {
        var _this = this;
        console.log(this.registrationUser);
        this.errorMessage = null;
        this.userRegistration.newPassword(this.registrationUser, function (result, error) {
            if (error) {
                _this.errorMessage = error;
                return;
            }
            if (result) {
                _this.router.navigate(['/pg/newPassword']);
            }
        });
    };
    return NewPasswordComponent;
}());
export { NewPasswordComponent };
