import { OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
//import { UserLoginService } from "../../../services/aws/user-login.service";
//import { CognitoUtil } from "../../../services/aws/cognito.service";
import { LoginServices } from "../../../services/login.services";
import { DataSharingService, UniversalUser } from '../../../services/shared.service';
import { SendOtp } from '../../../models/sendotp.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from "../../../models/user.model";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, dataSharingService, universalUser, loginService, spinner) {
        this.router = router;
        this.dataSharingService = dataSharingService;
        this.universalUser = universalUser;
        this.loginService = loginService;
        this.spinner = spinner;
        this.otpSent = false;
        this.sendOtp = new SendOtp();
        console.log("LoginComponent constructor");
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.router.navigate(['/pg/login']);
        this.sendOtp = new SendOtp();
    };
    LoginComponent.prototype.ngOnDestroy = function () {
    };
    LoginComponent.prototype.verifyCustomerOtp = function () {
        var _this = this;
        this.spinner.show();
        this.loginService.verifyCustomerOtp(this.mobile, this.otp, true)
            .subscribe(function (response) {
            console.log("verify otp");
            console.log(response);
            _this.spinner.hide();
            if (response && response.success) {
                var user = new User();
                user.phoneNumber = _this.mobile;
                user.otp = _this.otp;
                _this.universalUser.setUser(user);
                _this.errorMessage = null;
                _this.router.navigate(['/pg/customer']);
            }
            if (response && response.error) {
                _this.errorMessage = response.error;
            }
        }, function (error) {
            console.log("verify otp error");
            console.log(error);
            _this.errorMessage = "Something went wrong, Please try again later.";
            _this.spinner.hide();
        });
    };
    LoginComponent.prototype.sendOtpToCustomer = function () {
        var _this = this;
        if (!this.mobile) {
            this.errorMessage = "Please enter mobile number";
            return;
        }
        this.spinner.show();
        this.loginService.sendOtp(this.mobile).subscribe(function (response) {
            console.log("send otp");
            console.log(response);
            if (response && response.success) {
                _this.otpSent = true;
            }
            if (response && response.error) {
                _this.errorMessage = response["error"].toString();
            }
            _this.spinner.hide();
        }, function (error) {
            console.log("send otp error");
            console.log(error);
            _this.errorMessage = "Something went wrong, Please try again";
            _this.spinner.hide();
        });
    };
    LoginComponent.prototype.sendOtpService = function () {
        var _this = this;
        this.spinner.show();
        if (this.mobile == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;
        this.loginService.checkMobile(this.mobile).subscribe(function (response) {
            console.log("Comments Saved..!!");
            console.log(response);
            _this.sendOtp = response;
            if (!_this.sendOtp.flag) {
                _this.errorMessage = _this.sendOtp.noData;
            }
        }, function (error) {
            console.log("error");
            _this.spinner.hide();
        });
        setTimeout(function () {
            _this.spinner.hide();
        }, 3000);
    };
    return LoginComponent;
}());
export { LoginComponent };
