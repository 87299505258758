var Activity = /** @class */ (function () {
    function Activity() {
        this.episodeIds = [];
        this.episodeChats = [];
        this.episodeChats = [];
        this.episodeIds = [];
    }
    return Activity;
}());
export { Activity };
var EpisodeChat = /** @class */ (function () {
    function EpisodeChat(episodeId, chatMessages) {
        this.episodeId = null;
        this.chatMessages = [];
        this.episodeId = episodeId != null ? episodeId : null;
        this.chatMessages = chatMessages != null ? chatMessages : [];
    }
    return EpisodeChat;
}());
export { EpisodeChat };
