import { ChatMessage } from './chatmessage.model';

export class Activity {
    episodeIds: string[] = [];
    episodeChats: EpisodeChat[] = [];

    constructor() {
        this.episodeChats = [];
        this.episodeIds = [];
    }
}

export class EpisodeChat {
    episodeId: string = null;
    chatMessages: ChatMessage[] = [];

    constructor(episodeId?: string, chatMessages?: ChatMessage[]) {
        this.episodeId = episodeId != null ? episodeId : null;
        this.chatMessages = chatMessages != null ? chatMessages : [];
    }

}