import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Customer } from '../models/customer.model';

@Injectable()
export class CustomerSevice {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    getCustomersByPhoneNumber(phoneNumber: string): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apigatewayurl + environment.masterdomainapi}`;
        const body = {
            "tableName" : "SALES_POLICY",
            "companyContext" : {
                companyId: environment.companyId
            },
            keyCondition: {
                customer_mobile: phoneNumber
            },
            "indexName" : "customer_mobile-index",
            operation: "GET_ALL"
        }
        const httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getCustomerByCreatedDt(createdDt: any): Observable<any> {
        createdDt = parseInt(createdDt.toString());
        const subject = new Subject<any>();   
        const url = `${environment.apigatewayurl + environment.masterdomainapi}`;
        const body = {
            "tableName" : "SALES_POLICY",
            "companyContext" : {
                companyId: environment.companyId
            },
            key: {
                "createdDt" : createdDt
            },
            operation: "GET_ONE"
        }
        const httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    updateCustomer(customer: Customer): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apigatewayurl + environment.masterdomainapi}`;
        const body = {
            "tableName" : "SALES_POLICY",
            "companyContext" : {
                companyId: environment.companyId
            },
            key: {
                entityType: customer.entityType,
                createdDt: customer.createdDt
            },
            value: customer,
            operation: "REPLACE"
        }
        const httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )

        return subject.asObservable();
    }

    updateFlow(customer: Customer): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.flowintegration}`;
        const body = {
            operation: "UPDATE",
            payload: JSON.stringify( { "salesPolicy" : customer}),
            param: "{}",
            entityId: customer.entityId,
            machineType: environment.machineType
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': "Bearer " + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
    
}
