<div *ngIf="verifyOtp.customer!=null">
    <div id="page" class="">
        <div id="page-header" class="header">
            <button id="burger"><i class="fa fa-bars"></i></button>
            <div id="header-logo">
                <a href="" class="logo-content">
                    <img src="assets/images/AMS-Logo.png" style="width: 100px;" />
                    <!-- Monarch <i>UI</i>
                        <span>The perfect solution for user interfaces</span> -->
                </a>
            </div>
        </div>
        <div id="" class="">
            <div class="">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-8 p-0 d-none d-md-block">
                            <img src="assets/images/banner.jpg" width="100%" style="height: calc(100vh - 102px);">
                        </div>
                        <div class="col pr-5">
                            <div *ngIf="allPolicyCancelled" class="card col">
                                <p>
                                    Dear Customer,<br><br> Your TechGuard device protection plan request has been cancelled due to non payment within the stipulated timeframe.
                                    <br><br> You can contact us on support@techguard.co for any queries.
                                </p>

                            </div>
                            <div *ngIf="getPendingPaymentCustomers().length > 1 && selectedCustomer == null" class="card col">
                                <h5>Please select the plan as there are multiple plans against your mobile number</h5>
                                <h5 class="pt-3">Select Plan</h5>
                                <form>
                                    <div class="inputGroup" *ngFor="let i = index; let cust of getPendingPaymentCustomers()">
                                        <label>
                                            <input type="radio" [(ngModel)]="selectedCreatedDt" name="selectedCreatedDt"
                                                [value]="cust.createdDt">
                                            {{cust.brandname}} - {{ cust.imei_sno_1 }} - <span class="rupee-symbol"> &#8377; </span> {{cust.premium_paid}}/-
                                        </label><br />
                                    </div>
                                </form>
                                <!-- <form>
                                <div class="form-check" *ngFor="let cust of customers">
                                    <label>
                                        <input [id]="'claimnumlabel' + cust.claimNumber" type="radio" ng-model="selectedClaimNumber" name="selectedClaimNumber" value="cust.claimNumber"> <span class="label-text">{{ cust.claimNumber }}</span>
                                    </label>
                                </div>
                            </form> -->
                                <button (click)="submitIMEI()" id="m_login_signin_submit" class="btn btn-otp m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--success">Submit</button>
                            </div>
                            <div class="card" *ngIf="selectedCustomer">
                                <div class="row">
                                    <div class="col-12 p-0">
                                        <div class="info-head">
                                            <h4>Plan Details</h4>
                                            <div *ngIf="errorMessage!=null" class="alert alert-danger">
                                                {{ errorMessage }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info">
                                    <div class="pay">
                                        <div class="row">
                                            <div class="col-6">
                                                <label>Customer Name</label>
                                            </div>
                                            <div class="col-6">
                                                <p>{{selectedCustomer.customer_fname}} {{selectedCustomer.customer_lname}}</p>
                                            </div>
                                            <div class="col-6">
                                                <label>Email ID</label>
                                            </div>
                                            <div class="col-6">
                                                <p>{{selectedCustomer.customer_email}}</p>
                                            </div>
                                            <div class="col-6">
                                                <label>Device Brand</label>
                                            </div>
                                            <div class="col-6">
                                                <p>{{selectedCustomer.brandname}}</p>
                                            </div>
                                            <div class="col-6">
                                                <label>Device Model</label>
                                            </div>
                                            <div class="col-6">
                                                <p>{{selectedCustomer.model}}</p>
                                            </div>
                                            <div class="col-6">
                                                <label>IMEI/Serial Number</label>
                                            </div>
                                            <div class="col-6">
                                                <p>{{selectedCustomer.imei_sno_1}}</p>
                                            </div>
                                            <div class="col-6">
                                                <label>Amount Payable</label>
                                            </div>
                                            <div class="col-6">
                                                <p><span class="rupee-symbol"> &#8377; </span> {{ selectedCustomer.premium_paid }}/-</p>
                                            </div>
                                            <!-- <div class="col-6">
                                                <label>Payment Status </label>
                                            </div>
                                            <div *ngIf="selectedCustomer.isPaymentDone else failed" class="col-md-6">
                                                <p>Paid</p>
                                            </div>
                                            <ng-template #failed>
                                                <div class="col-6">
                                                    <p>Pending</p>
                                                </div>
                                            </ng-template> -->
                                            <div *ngIf="this.paymentStatus == null && !selectedCustomer.isPaymentDone" class="col-12 text-center">
                                                <button (click)="razorPaymentOrder(selectedCustomer.premium_paid)" class="btn-otp">Pay Now</button>
                                            </div>

                                            <!-- FOR FAILED PAYMENT -->

                                            <div *ngIf="this.paymentStatus != null && this.paymentStatus == 'Failed' && !selectedCustomer.isPaymentDone" class="col-12">
                                                <div class="row">
                                                    <div class="col-2 pr-0">
                                                        <img width="30" src="assets/images/fail.png">
                                                    </div>
                                                    <div class="col-10 pl-0">
                                                        <h5>Oops!</h5>
                                                        <p>It seems that the transaction was not successful! In case if the amount has been deducted from your account, kindly email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="col-12 text-center">
                                                            <button (click)="razorPaymentOrder(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- IF Payment is done and customer logs in again -->

                                            <div *ngIf="selectedCustomer.isPaymentDone" class="col-12">
                                                <div class="row">
                                                    <div class="col-2 pr-0">
                                                        <img width="30" src="assets/images/done.png">
                                                    </div>
                                                    <div class="col-10 pl-0">
                                                        <h5>Thank you!</h5>
                                                        <p>It seems that you have already paid for this plan. If any queries kindly email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="col-12 text-center">
                                                            <button (click)="razorPaymentOrder(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!--<div class="pay">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>BER Service Fee Amount</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p>NA</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>Payment Status</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p>Pending</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button class="pay-now">Pay Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                            <div *ngIf="customers && customers.length > 0 && paymentStatus == null && isAllPoliciesPaymentDone()" class="card">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 pr-0">
                                            <img width="30" src="assets/images/done.png">
                                        </div>
                                        <div class="col-10 pl-0">
                                            <h5>Thank you!</h5>
                                            <p>It seems that you have already paid for all plans. If any queries kindly email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                        </div>
                                        <div class="col-12">
                                            <!-- <div class="col-12 text-center">
                                                <button (click)="paynow(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="customers && customers.length > 1 && paymentStatus == null && isAllPoliciesCancelled()" class="card">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 pr-0">
                                            <img width="30" src="assets/images/done.png">
                                        </div>
                                        <div class="col-10 pl-0">
                                            <p>Dear Customer, we could not find any active plan against your mobile number. In case of any queries, please email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                        </div>
                                        <div class="col-12">
                                            <!-- <div class="col-12 text-center">
                                                <button (click)="paynow(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="selectedCustomer != null && paymentStatus == null && selectedCustomer.isPolicyCancelled" class="card">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 pr-0">
                                            <img width="30" src="assets/images/done.png">
                                        </div>
                                        <div class="col-10 pl-0">
                                            <p>Dear Customer, we could not find any active plan against your mobile number. In case of any queries, please email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                        </div>
                                        <div class="col-12">
                                            <!-- <div class="col-12 text-center">
                                                <button (click)="paynow(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="paymentStatus && paymentStatus=='Credit'" class="card">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 pr-0">
                                            <img width="30" src="assets/images/done.png">
                                        </div>
                                        <div class="col-10 pl-0">
                                            <h5>Thank you!</h5>
                                            <p>Payment has been done successfully. If any queries kindly email us at <a href="mailto:support@techguard.in">support@techguard.in</a></p>
                                        </div>
                                        <div class="col-12">
                                            <!-- <div class="col-12 text-center">
                                                <button (click)="paynow(selectedCustomer.premium_paid)" class="btn-otp">Retry</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <ngx-spinner type="line-spin-clockwise-fade">Loading..</ngx-spinner>
                </div>
            </div>
        </div>
    </div>
</div>