import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SuccessResponse } from '../models/success.model'
import { Customer } from '../models/customer.model'
import { Profile } from '../models/profile.model'
import { RazorPayOrder } from '../models/payment.model';

@Injectable()
export class SaveServices {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json','Access-Control-Allow-Origin':'*'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    saveDocument(customer: Customer): Observable<SuccessResponse> {
        const subject = new Subject<SuccessResponse>();
        var url ='';
        url = `${environment.apiurl + environment.uploadDoc}`;
        
        this.httpClient.post<SuccessResponse>(
            url,
            customer,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<SuccessResponse>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    payNow(paymodel: Profile): Observable<any> {
        const subject = new Subject<any>();
        var url ='';
        url = `${environment.apiurl + environment.paynow}`;
        
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });

        this.httpClient.post<any>(
            url,
            paymodel,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    razorPayOrder(razorPayOrder: RazorPayOrder): Observable<any> {
        const subject = new Subject<any>();
        var url ='';
        url = `${environment.apiurl + environment.razorpay_payment_api}`;
        
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        const body = {}
        body["order"] = razorPayOrder;
        //body["companyContext"] = { "companyId" : "e95764c923e74e308d0019516b17cabd" };
        body["functionInstanceName"] = "AMTRUST_RAZORPAY_CRED";
        body["operation"] = "CREATE_ORDER";
        
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    razorPayVerifySignture(checkoutResponse: any): Observable<any> {
        const subject = new Subject<any>();
        var url ='';
        url = `${environment.apiurl + environment.razorpay_payment_api}`;
        
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        const body = {}
        body["paymentInfo"] = checkoutResponse;
        //body["companyContext"] = { "companyId" : "e95764c923e74e308d0019516b17cabd" };
        body["functionInstanceName"] = "AMTRUST_RAZORPAY_CRED";
        body["operation"] = "VERIFY_SIGNATURE";
        
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}