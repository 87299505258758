import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';
import {AWSUploadedDoc} from '../models/documents.model'

@Injectable()
export class UploadService {

  constructor() { }
  awsUploadedDoc: AWSUploadedDoc
  
  
}
