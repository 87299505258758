import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var LoginServices = /** @class */ (function () {
    function LoginServices(httpClient) {
        this.httpClient = httpClient;
        this.FUNCTION_INSTATNCE_NAME = "AMTRUST_SALES_CUSTOMER";
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        });
    }
    LoginServices.prototype.sendOtp = function (phoneNumber) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.sendotp);
        var body = {
            operation: "SMS_TO_CLAIM_CUSTOMER_OTP",
            phoneNumber: phoneNumber,
            functionInstanceName: this.FUNCTION_INSTATNCE_NAME,
            template: "%(otp)s is your OTP to login and make the payment for your TechGuard plan. Your OTP is valid for 30 mins, please do not share your OTP for security reasons.",
            senderName: "TECGRD"
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    LoginServices.prototype.checkMobile = function (mobile) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.checkMobileNo);
        var body = { "phoneNumber": mobile, functionInstanceName: this.FUNCTION_INSTATNCE_NAME };
        this.httpClient.post(url, body, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    LoginServices.prototype.verifyCustomerOtp = function (mobile, otp, getCustomer) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.verifyOtp);
        var body = {
            phoneNumber: mobile,
            otp: otp
        };
        if (getCustomer) {
            body["GET_CUSTOMER"] = true;
        }
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    LoginServices.prototype.verifyOtp = function (mobile, otp) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.verifyOtp);
        var body = { "phoneNumber": mobile, "otp": otp };
        this.httpClient.post(url, body, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return LoginServices;
}());
export { LoginServices };
