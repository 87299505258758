import { OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { Subscription } from 'rxjs/Subscription';
import { UserLoginService } from '../../services/aws/user-login.service';
import { CognitoUtil } from "../../services/aws/cognito.service";
import { Subscription } from "rxjs";
import * as moment from 'moment/moment';
import { SearchResponse } from '../../models/searchresponse.model';
import { Activity, EpisodeChat } from '../../models/activity.model';
import { EventData } from '../../models/event.model';
import { SearchService } from '../../services/search.service';
import { SaveServices } from '../../services/save.services';
import { ConversationService } from '../../services/conversation.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(document, router, route, userService, cognitoUtil, searchService, saveService, conversationService, sanitizer, spinner, datePipe) {
        var _this = this;
        this.document = document;
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.cognitoUtil = cognitoUtil;
        this.searchService = searchService;
        this.saveService = saveService;
        this.conversationService = conversationService;
        this.sanitizer = sanitizer;
        this.spinner = spinner;
        this.datePipe = datePipe;
        this.selectedClientName = '';
        this.selectedPolicyNo = '';
        this.searchFl = false;
        this.enteredIMEINo = '';
        this.home = {
            email: '',
            name: '',
            mobile: '',
            alternatemobile: '',
            imei: '',
            policy_imei: '',
            policyid: '',
            bill_add: '',
            policystatus: '',
            make: '',
            model: '',
            mobilecost: '',
            excessfee: '',
            policystartdate: '',
            policyenddate: '',
            mpd: '',
            pp: '',
            clientname: '',
            protectiontype: '',
            policytype: '',
            programtype: '',
            programname: '',
            underwritername: '',
            policy_imei2: '',
            policyid2: '',
            bill_add2: '',
            policystatus2: '',
            make2: '',
            model2: '',
            mobilecost2: '',
            excessfee2: '',
            policystartdate2: '',
            policyenddate2: '',
            mpd2: '',
            pp2: '',
            clientname2: '',
            protectiontype2: '',
            policytype2: '',
            programtype2: '',
            programname2: '',
            underwritername2: ''
        };
        this.claimResult = [];
        this.policyIndex = 0;
        this.commentsFormdescription = null;
        this.commentsFormassignTo = null;
        this.commentsFormpriority = null;
        this.commentsFormstatus = null;
        this.commentsFormcallType = null;
        this.virtualAgentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.virtualAgentUrl);
        this.fetchCRMError = false;
        // Activity information starts here
        this.active = 0;
        this.sort = 0;
        this.timeLeft = 0;
        this.minutes = 0;
        this.secondadd = "0";
        this.minuteadd = "0";
        this.searchResult = new SearchResponse();
        window.addEventListener('message', function (event) {
            console.log("amtrust listen event");
            if (event && event.data) {
                var eventData = event.data;
                if (eventData && eventData.eventId && eventData.eventId == "IMEI") {
                    _this.textToBeSearch = eventData.messageText;
                    _this.searchText();
                }
            }
        });
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.cognitoValidateSession();
        this.cti_imei = this.route.snapshot.paramMap.get("imei");
        if (this.cti_imei != null) {
            this.textToBeSearch = this.cti_imei;
            this.searchText();
        }
        console.log("IMEI Received in the URL:" + this.cti_imei);
    };
    HomeComponent.prototype.activity = function () {
        this.active = 1;
    };
    HomeComponent.prototype.sorted = function () {
        if (this.sort == 0)
            this.sort = 1;
        else
            this.sort = 0;
    };
    // Activity information ends here
    HomeComponent.prototype.doAction = function (action) {
        console.log(action);
    };
    HomeComponent.prototype.cognitoSignIn = function () {
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.refresh = function () {
        window.location.reload();
    };
    HomeComponent.prototype.cognitoSignUp = function () {
        this.router.navigate(['/pg/register']);
    };
    HomeComponent.prototype.cognitoChangePwd = function () {
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.cognitoSignOut = function () {
        if (this.userLoggedIn) {
            this.userService.logout();
            this.cognitoValidateSession();
        }
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.cognitoGetSession = function () {
        var _this = this;
        this.cognitoUtil.getSession(function (session) {
            if (session) {
                _this.session = session;
                session.getAccessToken().getJwtToken();
                session.getAccessToken().getExpiration();
                session.getIdToken().getJwtToken();
                session.getIdToken().getExpiration();
                session.getRefreshToken().getToken();
            }
        });
    };
    HomeComponent.prototype.formatDate = function (date) {
        var dateOut = new Date(date);
        return dateOut;
    };
    HomeComponent.prototype.cognitoValidateSession = function () {
        var _this = this;
        this.userService.isAuthenticated(function (isValiedSession, error) {
            if (error) {
                _this.userLoggedIn = false;
                console.log('HomeComponent: in cognitoValidateSession: ' + error);
                _this.router.navigate(['/pg/login']);
                return;
            }
            _this.userLoggedIn = isValiedSession;
            console.log('is valid session ');
            console.log(isValiedSession);
            if (!isValiedSession) {
                _this.router.navigate(['/pg/login']);
            }
            else {
                _this.user = _this.userService.getuser();
                console.log('user present');
                console.log(_this.user);
                _this.username = _this.user.username;
                console.log(_this.username);
            }
        });
    };
    HomeComponent.prototype.navigateToProfile = function () {
        this.router.navigate(['/pg/profile']);
    };
    HomeComponent.prototype.onclickpolicytab = function (salesorder_no) {
        this.claimResult = [];
        for (var _i = 0, _a = this.searchResult.claims; _i < _a.length; _i++) {
            var cl = _a[_i];
            if (cl.salesorder_no == salesorder_no) {
                console.log(salesorder_no);
                this.claimResult.push(cl);
            }
        }
    };
    // search text
    HomeComponent.prototype.searchText = function () {
        var _this = this;
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.startTimer();
        this.data_not_found = false;
        this.fetchCRMError = false;
        this.spinner.show();
        setTimeout(function () {
            /** spinner ends after 5 seconds */
            _this.spinner.hide();
        }, 6000);
        if (this.textToBeSearch) {
            this.searchSubscription = this.searchService.search(this.textToBeSearch)
                .subscribe(function (response) {
                console.log("search result");
                _this.searchResult = response;
                if (_this.searchResult.policies.length > 0) {
                    _this.onclickpolicytab(response.policies[0].salesorder_no);
                    _this.selectedClientName = _this.searchResult.policies[0].clientName;
                    _this.selectedPolicyNo = _this.searchResult.policies[0].salesorder_no;
                    _this.searchFl = true;
                }
                else {
                    _this.data_not_found = true;
                }
                _this.spinner.hide();
                _this.spinner.hide();
            }, function (error) {
                console.log("error");
                _this.spinner.hide();
                _this.fetchCRMError = true;
            });
        }
    };
    // get chat messages 
    HomeComponent.prototype.getChat = function (episodeIds) {
        var _this = this;
        var _loop_1 = function (episodeId) {
            this_1.conversationSubscriptions = this_1.conversationService.getChatMessages(episodeId)
                .subscribe(function (response) {
                console.log("chat messages");
                console.log(response);
                if (_this.searchResult.activity == null) {
                    _this.searchResult.activity = new Activity();
                }
                _this.searchResult.activity.episodeChats.push(new EpisodeChat(episodeId, response));
                console.log(_this.searchResult.activity);
            }, function (error) {
            });
        };
        var this_1 = this;
        for (var _i = 0, episodeIds_1 = episodeIds; _i < episodeIds_1.length; _i++) {
            var episodeId = episodeIds_1[_i];
            _loop_1(episodeId);
        }
    };
    HomeComponent.prototype.onPolicyClick = function (policyIndex, salesorder_no) {
        console.log("onPolicyClick");
        console.log(policyIndex);
        this.policyIndex = policyIndex;
        this.onclickpolicytab(salesorder_no);
        this.selectedClientName = this.searchResult.policies[policyIndex].clientName;
        this.selectedPolicyNo = this.searchResult.policies[policyIndex].salesorder_no;
    };
    HomeComponent.prototype.getStringDateFromMillis = function (millis) {
        return moment(new Date(millis)).format("HH:mm");
    };
    HomeComponent.prototype.isPolicyStatusOtherThanCurrent = function (policyStatus) {
        if (policyStatus == null || policyStatus.trim().length == 0) {
            return false;
        }
        if (policyStatus.trim().toUpperCase() != "CURRENT") {
            return true;
        }
        return false;
    };
    HomeComponent.prototype.isDateLessThanToday = function (dateToBecompare) {
        if (dateToBecompare != null && dateToBecompare.trim().length > 0) {
            var dateObjToBecompare = new Date(Date.parse(dateToBecompare));
            var todayDate = new Date();
            if (dateObjToBecompare <= todayDate) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.startTimer = function () {
        var _this = this;
        this.interval = setInterval(function () {
            if (_this.timeLeft < 60) {
                _this.timeLeft++;
                if (_this.timeLeft < 10) {
                    _this.secondadd = "0";
                }
                else {
                    _this.secondadd = "";
                }
            }
            else {
                _this.minutes++;
                _this.timeLeft = 0;
                _this.secondadd = "0";
                if (_this.minutes < 10) {
                    _this.minuteadd = "0";
                }
                else {
                    _this.minuteadd = "";
                }
            }
        }, 1000);
    };
    HomeComponent.prototype.clearTimer = function () {
        clearInterval(this.interval);
        this.minutes = 0;
        this.timeLeft = 0;
        this.secondadd = "0";
        this.minuteadd = "0";
    };
    HomeComponent.prototype.lessThanThirtyDays = function (dateToBecompare) {
        if (dateToBecompare != null && dateToBecompare.trim().length > 0) {
            var dateObjToBecompare = new Date(Date.parse(dateToBecompare));
            dateObjToBecompare.setDate(dateObjToBecompare.getDate() - 30);
            var todayDate = new Date();
            if (dateObjToBecompare <= todayDate) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.newChat = function () {
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.startTimer();
        var event = new EventData();
        event.eventId = "CONVERSATION_RESTART";
        event.messageText = "start";
        event.displayText = "start";
        event.formResult = {};
        this.textToBeSearch = null;
        this.searchResult = new SearchResponse();
        this.searchFl = false;
        if (this.textToBeSearch) {
            event.contextData = { imei: null };
        }
        this.raiseEventToIframe(event);
    };
    HomeComponent.prototype.refreshChat = function () {
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.startTimer();
        var event = new EventData();
        event.eventId = "CONVERSATION_RESTART";
        event.messageText = "start";
        event.displayText = "start";
        event.formResult = {};
        if (this.textToBeSearch) {
            event.contextData = { imei: this.textToBeSearch };
        }
        this.raiseEventToIframe(event);
    };
    HomeComponent.prototype.raiseEventToIframe = function (eventData) {
        console.log("raise event");
        console.log(eventData);
        this.autoBotRefId.nativeElement.contentWindow.postMessage(eventData, "*");
    };
    return HomeComponent;
}());
export { HomeComponent };
