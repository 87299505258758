import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var SaveServices = /** @class */ (function () {
    function SaveServices(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        });
    }
    SaveServices.prototype.saveDocument = function (customer) {
        var subject = new Subject();
        var url = '';
        url = "" + (environment.apiurl + environment.uploadDoc);
        this.httpClient.post(url, customer, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    SaveServices.prototype.payNow = function (paymodel) {
        var subject = new Subject();
        var url = '';
        url = "" + (environment.apiurl + environment.paynow);
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, paymodel, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    SaveServices.prototype.razorPayOrder = function (razorPayOrder) {
        var subject = new Subject();
        var url = '';
        url = "" + (environment.apiurl + environment.razorpay_payment_api);
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        var body = {};
        body["order"] = razorPayOrder;
        //body["companyContext"] = { "companyId" : "e95764c923e74e308d0019516b17cabd" };
        body["functionInstanceName"] = "AMTRUST_RAZORPAY_CRED";
        body["operation"] = "CREATE_ORDER";
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    SaveServices.prototype.razorPayVerifySignture = function (checkoutResponse) {
        var subject = new Subject();
        var url = '';
        url = "" + (environment.apiurl + environment.razorpay_payment_api);
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        var body = {};
        body["paymentInfo"] = checkoutResponse;
        //body["companyContext"] = { "companyId" : "e95764c923e74e308d0019516b17cabd" };
        body["functionInstanceName"] = "AMTRUST_RAZORPAY_CRED";
        body["operation"] = "VERIFY_SIGNATURE";
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return SaveServices;
}());
export { SaveServices };
