import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router ,NavigationExtras} from "@angular/router";
//import { UserLoginService } from "../../../services/aws/user-login.service";
//import { CognitoUtil } from "../../../services/aws/cognito.service";
import { LoginServices } from "../../../services/login.services";
import { DataSharingService, UniversalUser } from '../../../services/shared.service'
//import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Subscription } from "rxjs";
import { SuccessResponse } from '../../../models/success.model'
import { VerifyOtp } from '../../../models/verifyotp.model'
import { SendOtp } from '../../../models/sendotp.model'
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from "../../../models/user.model";

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
    mobile: string;
    otp: string;
    errorMessage: string;
    saveResponse: SuccessResponse;
    verifyOtp: VerifyOtp;
    sendOtp: SendOtp;
    otpSent: boolean = false;

    private subscriptionAddUserToGroup: Subscription;

    constructor(
        public router: Router,
        private dataSharingService: DataSharingService,
        private universalUser: UniversalUser,
        public loginService: LoginServices,public spinner : NgxSpinnerService
    ) {
        this.sendOtp=new SendOtp();
        console.log("LoginComponent constructor");
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.router.navigate(['/pg/login']);
        this.sendOtp=new SendOtp();
    }

    ngOnDestroy() {
       
    }

    verifyCustomerOtp() {
      this.spinner.show();
      this.loginService.verifyCustomerOtp(this.mobile, this.otp, true)
        .subscribe(
          response => {
            console.log("verify otp");
            console.log(response)
            this.spinner.hide();
            if (response && response.success) {
              const user = new User();
              user.phoneNumber = this.mobile;
              user.otp = this.otp;
              this.universalUser.setUser(user);
              this.errorMessage = null;
              this.router.navigate(['/pg/customer']);
              
            }
            if (response && response.error) {
              this.errorMessage = response.error
            }
          },
          error => {
            console.log("verify otp error");
            console.log(error);
            this.errorMessage = "Something went wrong, Please try again later.";
            this.spinner.hide();
          }
        )
    }

    sendOtpToCustomer() {
      if (!this.mobile) {
        this.errorMessage = "Please enter mobile number";
        return;
      }
      this.spinner.show();
      this.loginService.sendOtp(this.mobile).subscribe(
        response => {
          console.log("send otp");
          console.log(response);
          if (response && response.success) {
            this.otpSent = true;
          }
          if (response && response.error) {
            this.errorMessage = response["error"].toString();
          }
          this.spinner.hide()
        },
        error => {
          console.log("send otp error");
          console.log(error);
          this.errorMessage = "Something went wrong, Please try again"
          this.spinner.hide();
        }
      )
    }

    sendOtpService() {
      this.spinner.show();
        if (this.mobile == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;
        this.loginService.checkMobile(this.mobile).subscribe(
            response => {
              console.log("Comments Saved..!!");
              console.log(response);
              this.sendOtp = response;
              if (!this.sendOtp.flag)
              {
                this.errorMessage=this.sendOtp.noData
              }  
              
            },
            error => {
              console.log("error");
              this.spinner.hide();
            }
          )
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
    }

    
}