// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //apiurl: 'http://localhost:5010',
  apigatewayurl: 'https://qnqx1potj0.execute-api.ap-south-1.amazonaws.com/testing',
  apiurl: 'https://api.automatapi.com',
  masterdomainapi: '/api/amtrust/amtrustmasterdomain',
  flowintegration: '/api/flow/flowintegration',
  searchApi: '/amtrust/cti/fetchdata',
  saveComment: '/amtrust/secondcrmindia/saveclaimcomments',
  savePolicyComment: '/amtrust/savepolicycomments',
  getMessages: '/getMessages',
  virtualAgentUrl: 'http://automatapi-auto-workbench.s3-website.ap-south-1.amazonaws.com/#/wb/5c8a2ffecdc0530001afb728?displaySideBar=false',
  companyId: 'e95764c923e74e308d0019516b17cabd',
  bearertoken: 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJlOTU3NjRjOTIzZTc0ZTMwOGQwMDE5NTE2YjE3Y2FiZCIsImlhdCI6MTUxNjA4MzcwNiwic3ViIjoiQW10cnVzdCIsImlzcyI6ImYxNzg3OTM0ZDI2NDQ3ZDRhNzk4NDIyMGM2ZTE3NGU2IiwibmJmIjowLCJleHAiOjQxMDIzMzg2MDB9.IhoGxPiKzqRaHtSJ-ppus26miYUoUzzZStjS0E6_LDo',
  apigatewayapiKey: 'pp3exLKtIr64kr7Sf5ZYf5nrHEEmERkA2z0A35BQ',
  machineType: 'SalesAPI',

  // **** customer portal **** // 
  sendotp: '/api/amtrust/customersendsms',
  checkMobileNo: '/amtrust/customer/sendotp',
  verifyOtp: '/api/amtrust/verifyotp',
  claims: '/api/secondcrmindia/transformclaimbyimei',
  claimcustomer: '/api/amtrust/claimcustomer',
  updateClaim: '/api/secondcrmindia/updateclaimsbyimei',
  uploadUrl: '/uploadConverseData',
  uploadDoc: '/amtrust/customer/saveupdatedoc',
  updatePayment: '/amtrust/customer/paymentupdate',
  paynow: '/api/payment/instamojo/create',
  //RETURN_URL: 'http://localhost:5000/',
  //RETURN_URL: 'https://sales.amsindia.tech/#/pg/customer',
  //RETURN_URL: 'http://localhost:4200/#/pg/customer',
  //RETURN_URL: 'https://sales.amsindia.tech/#/pg/customer',
  
  RETURN_URL: 'http://localhost:4200/#/pg/customer',
  //RETURN_URL: 'https://amtrust-sales-customer.automatapi.io/#/pg/customer',
  // ******* AWS Properties ******* //
  AWS_ACCESS_KEY:'AKIAJIWV4CVN42RZ5QCQ',
  AWS_SECRET:'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',
  AWS_BUCKET_NAME:'amtrust-customer',
  AWS_BUCKET_URL:'https://s3.ap-south-1.amazonaws.com/amtrust-customer/',
  AWS_FILE_LOCATION:'https://amtrust-customer.s3.ap-south-1.amazonaws.com/',
  AWS_REGION:'ap-south-1',


  region: 'ap-south-1',

  //userPoolId: 'ap-south-1_CHh5zZo1Y',
  //clientId: '13792pc8li623cka6e88993jk8',
  userPoolId: 'ap-south-1_nc0aHeJkq',
  clientId: '5shksgl03hu3sb34p4r1vd534o',

  razorpay_payment_api: '/api/payment/razorpay',


  cognito_idp_endpoint: '',

  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',
  // ******* AWS Properties ******* //

  // ******* AWS Endpoints ******* //
  awsServerUrl: 'https://amtrust-agent.auth.ap-south-1.amazoncognito.com',
  awsDynamoCrud: 'testing/dynamo/crud',
  // ******* AWS Endpoints ******* //

  serverUrl: 'https://demo-console.automatapi.com/',
  flowUrl: 'flow/api/withState/'
  // ******* API Endpoints ******* //
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
