import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var CustomerSevice = /** @class */ (function () {
    function CustomerSevice(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    CustomerSevice.prototype.getCustomersByPhoneNumber = function (phoneNumber) {
        var subject = new Subject();
        var url = "" + (environment.apigatewayurl + environment.masterdomainapi);
        var body = {
            "tableName": "SALES_POLICY",
            "companyContext": {
                companyId: environment.companyId
            },
            keyCondition: {
                customer_mobile: phoneNumber
            },
            "indexName": "customer_mobile-index",
            operation: "GET_ALL"
        };
        var httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.getCustomerByCreatedDt = function (createdDt) {
        createdDt = parseInt(createdDt.toString());
        var subject = new Subject();
        var url = "" + (environment.apigatewayurl + environment.masterdomainapi);
        var body = {
            "tableName": "SALES_POLICY",
            "companyContext": {
                companyId: environment.companyId
            },
            key: {
                "createdDt": createdDt
            },
            operation: "GET_ONE"
        };
        var httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.updateCustomer = function (customer) {
        var subject = new Subject();
        var url = "" + (environment.apigatewayurl + environment.masterdomainapi);
        var body = {
            "tableName": "SALES_POLICY",
            "companyContext": {
                companyId: environment.companyId
            },
            key: {
                entityType: customer.entityType,
                createdDt: customer.createdDt
            },
            value: customer,
            operation: "REPLACE"
        };
        var httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.apigatewayapiKey
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.updateFlow = function (customer) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.flowintegration);
        var body = {
            operation: "UPDATE",
            payload: JSON.stringify({ "salesPolicy": customer }),
            param: "{}",
            entityId: customer.entityId,
            machineType: environment.machineType
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': "Bearer " + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return CustomerSevice;
}());
export { CustomerSevice };
