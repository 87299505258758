import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
//import { Subscription } from 'rxjs/Subscription';
import { UserLoginService } from '../../services/aws/user-login.service';
import { CognitoUtil } from "../../services/aws/cognito.service";
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { Subscription, from } from "rxjs";
import * as moment from 'moment/moment';

import { Home } from '../../models/home';
import { SearchResponse } from '../../models/searchresponse.model'
import { SuccessResponse } from '../../models/success.model'
import { Claim } from '../../models/claim.model'
import { Activity, EpisodeChat } from '../../models/activity.model';
import { EventData } from '../../models/event.model'


import { SearchService } from '../../services/search.service'
import { SaveServices } from '../../services/save.services'
import { ConversationService } from '../../services/conversation.service'
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval } from 'rxjs';
import { Comments } from '../../models/comments.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('autoBot') autoBotRefId: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserLoginService,
    private cognitoUtil: CognitoUtil,
    private searchService: SearchService,
    private saveService: SaveServices,
    private conversationService: ConversationService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {
    this.searchResult = new SearchResponse();
    window.addEventListener('message', (event) => {
      console.log("amtrust listen event");

      if (event && event.data) {
        const eventData = event.data;
        if (eventData && eventData.eventId && eventData.eventId == "IMEI") {
          this.textToBeSearch = eventData.messageText;
          this.searchText();
        }
      }
    }
    )
  }

  userLoggedIn: boolean;
  selectedClientName: string = '';
  selectedPolicyNo: string = '';
  searchFl: boolean = false;
  session: CognitoUserSession;
  enteredIMEINo: string = '';

  home: Home = {
    email: '',
    name: '',
    mobile: '',
    alternatemobile: '',
    imei: '',
    policy_imei: '',
    policyid: '',
    bill_add: '',
    policystatus: '',
    make: '',
    model: '',
    mobilecost: '',
    excessfee: '',
    policystartdate: '',
    policyenddate: '',
    mpd: '',
    pp: '',
    clientname: '',
    protectiontype: '',
    policytype: '',
    programtype: '',
    programname: '',
    underwritername: '',
    policy_imei2: '',
    policyid2: '',
    bill_add2: '',
    policystatus2: '',
    make2: '',
    model2: '',
    mobilecost2: '',
    excessfee2: '',
    policystartdate2: '',
    policyenddate2: '',
    mpd2: '',
    pp2: '',
    clientname2: '',
    protectiontype2: '',
    policytype2: '',
    programtype2: '',
    programname2: '',
    underwritername2: ''
  };

  
  searchResult: SearchResponse;
  claimResult: Claim[] = [];
  saveResponse: SuccessResponse;
  textToBeSearch: string;
  claimComment: string;
  policyIndex: number = 0;
  commentsForm:Comments;
  commentsFormdescription:string=null;
  commentsFormassignTo:string=null;
  commentsFormpriority: string=null;
  commentsFormstatus: string=null;
  commentsFormcallType:string=null;
  virtualAgentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.virtualAgentUrl);

  private searchSubscription: Subscription;
  private conversationSubscriptions: Subscription;
  user: any;
  username: string;
  cti_imei: string;
  data_not_found: boolean;
  fetchCRMError: boolean = false;

  ngOnInit() {
    this.cognitoValidateSession();
    this.cti_imei = this.route.snapshot.paramMap.get("imei");
    if (this.cti_imei != null) {
      this.textToBeSearch = this.cti_imei;
      this.searchText();
    }
    console.log("IMEI Received in the URL:" + this.cti_imei);
  }
  // Activity information starts here
  active: number = 0;
  activity(){
    this.active=1;
  }
  sort: number=0;
  sorted(){
    if(this.sort==0)
    this.sort=1;
    else
    this.sort=0;
  }
  // Activity information ends here

  doAction(action) {
    console.log(action);
  }
  cognitoSignIn() {
    this.router.navigate(['/pg/login']);
  }

  refresh(): void {
    window.location.reload();
  }

  cognitoSignUp() {
    this.router.navigate(['/pg/register']);
  }

  cognitoChangePwd() {
    this.router.navigate(['/pg/login']);
  }

  cognitoSignOut() {
    if (this.userLoggedIn) {
      this.userService.logout();
      this.cognitoValidateSession();
    }
    this.router.navigate(['/pg/login']);
  }

  cognitoGetSession() {
    this.cognitoUtil.getSession((session) => {
      if (session) {
        this.session = session;
        session.getAccessToken().getJwtToken();
        session.getAccessToken().getExpiration();
        session.getIdToken().getJwtToken();
        session.getIdToken().getExpiration();
        session.getRefreshToken().getToken();
      }
    });
  }
  formatDate(date) {
    var dateOut = new Date(date);
    return dateOut;
  }
  cognitoValidateSession() {
    this.userService.isAuthenticated((isValiedSession, error) => {
      if (error) {
        this.userLoggedIn = false;
        console.log('HomeComponent: in cognitoValidateSession: ' + error);
        this.router.navigate(['/pg/login']);
        return;
      }
      this.userLoggedIn = isValiedSession;
      console.log('is valid session ');
      console.log(isValiedSession);
      if (!isValiedSession) {
        this.router.navigate(['/pg/login']);
      }
      else {
        this.user = this.userService.getuser();
        console.log('user present');
        console.log(this.user);
        this.username = this.user.username;
        console.log(this.username)
      }
    });
  }

  navigateToProfile() {
    this.router.navigate(['/pg/profile']);
  }

  onclickpolicytab(salesorder_no: string) {
    this.claimResult = []
    for (let cl of this.searchResult.claims) {
      if (cl.salesorder_no == salesorder_no) {
        console.log(salesorder_no);
        this.claimResult.push(cl);
      }
    }

  }
  // search text
  searchText() {

    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.startTimer();
    this.data_not_found = false;
    this.fetchCRMError = false;
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 6000);


    if (this.textToBeSearch) {
      this.searchSubscription = this.searchService.search(this.textToBeSearch)
        .subscribe(
          response => {
            console.log("search result");
            this.searchResult = response;
            if (this.searchResult.policies.length > 0) {
              this.onclickpolicytab(response.policies[0].salesorder_no);
              this.selectedClientName = this.searchResult.policies[0].clientName;
              this.selectedPolicyNo = this.searchResult.policies[0].salesorder_no;
              this.searchFl = true;
            }
            else {
              this.data_not_found = true;
            }
            this.spinner.hide();
            this.spinner.hide();
          },
          error => {
            console.log("error");
            this.spinner.hide();
            this.fetchCRMError = true;
          }
        )
    }

  }


  
  

  // get chat messages 
  getChat(episodeIds: string[]) {
    for (let episodeId of episodeIds) {
      this.conversationSubscriptions = this.conversationService.getChatMessages(episodeId)
        .subscribe(
          response => {
            console.log("chat messages");
            console.log(response);
            if (this.searchResult.activity == null) {
              this.searchResult.activity = new Activity();
            }
            this.searchResult.activity.episodeChats.push(new EpisodeChat(episodeId, response));
            console.log(this.searchResult.activity);
          },
          error => {

          }
        )
    }

  }

  onPolicyClick(policyIndex: number, salesorder_no: string) {
    console.log("onPolicyClick");
    console.log(policyIndex);
    this.policyIndex = policyIndex;
    this.onclickpolicytab(salesorder_no);
    this.selectedClientName = this.searchResult.policies[policyIndex].clientName;
    this.selectedPolicyNo = this.searchResult.policies[policyIndex].salesorder_no;
  }

  getStringDateFromMillis(millis: number) {
    return moment(new Date(millis)).format("HH:mm");
  }

  isPolicyStatusOtherThanCurrent(policyStatus: string) {
    if (policyStatus == null || policyStatus.trim().length == 0) {
      return false;
    }
    if (policyStatus.trim().toUpperCase() != "CURRENT") {
      return true;
    }
    return false;
  }

  isDateLessThanToday(dateToBecompare: string) {
    if (dateToBecompare != null && dateToBecompare.trim().length > 0) {
      const dateObjToBecompare = new Date(Date.parse(dateToBecompare));
      const todayDate = new Date();
      if (dateObjToBecompare <= todayDate) {
        return true;
      }
      return false;
    }
  }

  timeLeft: number = 0;
  minutes: number = 0;
  secondadd: string = "0";
  minuteadd: string = "0";
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft < 60) {
        this.timeLeft++;
        if (this.timeLeft < 10) {
          this.secondadd = "0";
        }
        else {
          this.secondadd = "";
        }
      } else {
        this.minutes++;
        this.timeLeft = 0;
        this.secondadd = "0";
        if (this.minutes < 10) {
          this.minuteadd = "0";
        }
        else {
          this.minuteadd = ""
        }
      }
    }, 1000)
  }

  clearTimer() {
    clearInterval(this.interval);
    this.minutes = 0;
    this.timeLeft = 0;
    this.secondadd = "0";
    this.minuteadd = "0";
  }


  lessThanThirtyDays(dateToBecompare: string) {
    if (dateToBecompare != null && dateToBecompare.trim().length > 0) {
      const dateObjToBecompare = new Date(Date.parse(dateToBecompare));
      dateObjToBecompare.setDate(dateObjToBecompare.getDate() - 30);
      const todayDate = new Date();
      if (dateObjToBecompare <= todayDate) {
        return true;
      }
      return false;
    }
  }

  newChat() {

    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.startTimer();
    const event = new EventData();
    event.eventId = "CONVERSATION_RESTART";
    event.messageText = "start";
    event.displayText = "start";
    event.formResult = {};
    this.textToBeSearch = null;
    this.searchResult = new SearchResponse();
    this.searchFl = false;
    if (this.textToBeSearch) {
      event.contextData = { imei: null };
    }
    this.raiseEventToIframe(event);

  }

  refreshChat() {
    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.startTimer();
    const event = new EventData();
    event.eventId = "CONVERSATION_RESTART";
    event.messageText = "start";
    event.displayText = "start";
    event.formResult = {};
    if (this.textToBeSearch) {
      event.contextData = { imei: this.textToBeSearch }
    }
    this.raiseEventToIframe(event);

  }

  raiseEventToIframe(eventData: EventData) {
    console.log("raise event");
    console.log(eventData);
    this.autoBotRefId.nativeElement.contentWindow.postMessage(
      eventData,
      "*"
    )

  }



}
