<div id="page">
    <div id="page-header" class="header">
        <button id="burger"><i class="fa fa-bars"></i></button>
        <div id="header-logo">
            <a href="" class="logo-content">
                <img src="assets/images/AMS-Logo.png" />
            </a>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-8 p-0 d-none d-md-block">
                <img style="height: calc(100vh - 102px);" src="assets/images/banner.jpg" width="100%">
            </div>
            <div class="col">
                <div class="card">
                    <div class="row">
                        <div class="col-2 pr-0">
                            <img src="assets/images/done.png">
                        </div>
                        <div class="col pl-0">
                            <h5>Transaction Successful!</h5>
                            <p>Thank you for the payment! We will share the plan e-certificate to your registered email id and mobile number within 72 working hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>