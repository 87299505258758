<div class="container-fluid">
    <div class="row" id="m_login" style="min-height: 100vh;">
        <div class="col-8 p-0 d-none d-md-block">
            <img class="img-fluid logo" src="assets/images/AMS-Logo.png" style="width: 100px;">
            <img style="height: 100vh" src="assets/images/banner.jpg" width="100%">
        </div>
        <div class="col">
            <img class="img-fluid d-block d-md-none" src="assets/images/AMS-Logo.png" style="width: 100px;">
            <div class="m-login__container">
                <div class="m-login__signin">
                    <div class="m-login__head pt-5">
                        <h2 class="m-login__title font-weight-bold text-dark">Log In with your Registered Mobile Number</h2>
                    </div>
                    <form class="m-login__form m-form" action="">
                        <div *ngIf="errorMessage!=null" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group m-form__group pt-5">
                            <label class="control-label" for="signupEmail">Mobile Number</label>
                            <input id="signupEmail" required type="email" maxlength="100" class="form-control" [(ngModel)]="mobile" [ngModelOptions]="{standalone: true}">
                        </div>
                        <ngx-spinner type="line-spin-clockwise-fade">Loading..</ngx-spinner>
                        <div *ngIf="otpSent else otppart">
                            <div class="form-group m-form__group">
                                <label class="control-label" for="signupPassword">OTP</label>
                                <input id="signupPassword" required type="password" maxlength="25" class="form-control" length="40" [(ngModel)]="otp" [ngModelOptions]="{standalone: true}">
                            </div>
                            <div class="m-login__form-action text-center">
                                <button (click)="verifyCustomerOtp()" id="m_login_signin_submit" class="btn btn-otp m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--success">Verify
                  Otp</button>
                            </div>
                        </div>
                        <ng-template #otppart>
                            <div class="m-login__form-action text-center">
                                <button (click)="sendOtpToCustomer()" id="m_login_signin_submit" class="btn btn-otp m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--success">Send
                  OTP</button>
                            </div>
                        </ng-template>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>