var CustomerInfo = /** @class */ (function () {
    function CustomerInfo() {
        this.name = null;
        this.mobileNo = null;
        this.imei = null;
        this.emailId = null;
        this.alternatePhoneNo = null;
        this.billingAddress = null;
    }
    return CustomerInfo;
}());
export { CustomerInfo };
