import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var SearchService = /** @class */ (function () {
    function SearchService(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    SearchService.prototype.search = function (searchText) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.searchApi);
        var body = { "imei-number": searchText, "companyContext": { "companyId": environment.companyId } };
        this.httpClient.post(url, body, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                subject.next(response.body);
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    return SearchService;
}());
export { SearchService };
