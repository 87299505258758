import { environment } from "../../../environments/environment";
import { CognitoUtil } from "./cognito.service";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import * as STS from "aws-sdk/clients/sts";
var UserLoginService = /** @class */ (function () {
    function UserLoginService(cognitoUtil) {
        this.cognitoUtil = cognitoUtil;
    }
    UserLoginService.prototype.getuser = function () {
        return this.cognitoUtil.getCurrentUser();
    };
    UserLoginService.prototype.authenticate = function (username, password, callbackFunction) {
        console.log("UserLoginService: starting the authentication");
        var authenticationData = {
            Username: username,
            Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        console.log("UserLoginService: Params set...Authenticating the user");
        var cognitoUser = new CognitoUser(userData);
        var self = this;
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                callbackFunction(null, "User needs to set password.");
            },
            onSuccess: function (result) {
                console.log("In authenticateUser onSuccess callback");
                // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
                // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
                // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
                // security credentials. The identity is then injected directly into the credentials object.
                // If the first SDK call we make wants to use our IdentityID, we have a
                // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
                // very innocuous API call that forces this behavior.
                var clientParams = {};
                if (environment.sts_endpoint) {
                    clientParams.endpoint = environment.sts_endpoint;
                }
                var sts = new STS(clientParams);
                sts.getCallerIdentity(function (err, data) {
                    callbackFunction(result, null);
                });
            },
            onFailure: function (err) {
                callbackFunction(null, err.message);
            },
        });
    };
    UserLoginService.prototype.forgotPassword = function (username, callbackFunction) {
        var userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function () {
            },
            onFailure: function (err) {
                callbackFunction(err.message);
            },
            inputVerificationCode: function () {
                callbackFunction(null);
            }
        });
    };
    UserLoginService.prototype.confirmNewPassword = function (email, verificationCode, password, callbackFunction) {
        var userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callbackFunction(null);
            },
            onFailure: function (err) {
                callbackFunction(err.message);
            }
        });
    };
    UserLoginService.prototype.logout = function () {
        console.log("UserLoginService: Logging out");
        this.cognitoUtil.getCurrentUser().signOut();
    };
    UserLoginService.prototype.isAuthenticated = function (callbackFunction) {
        var cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                    callbackFunction(false, err);
                }
                else {
                    console.log("UserLoginService: Session is " + session.isValid());
                    callbackFunction(session.isValid(), null);
                }
            });
        }
        else {
            console.log("UserLoginService: can't retrieve the current user");
            callbackFunction(false, "Can't retrieve the CurrentUser");
        }
    };
    return UserLoginService;
}());
export { UserLoginService };
